<template>
  <div>
    <b-row>
      <b-col
        v-if="!isBuyerTicket"
        md="3"
      >
        <validation-provider
          #default="{ errors }"
          name="Invoice Item No."
          :vid="`${validationId}_invoice_item_no`"
          rules="required"
        >
          <b-form-group
            label="Invoice Item No."
          >
            <b-form-input
              v-model="record.invoiceItemNumber"
              :state="errors.length > 0 ? false:null"
              type="number"
              min="0"
              placeholder="Invoice Item No."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Item Name"
          :vid="`${validationId}_item_name`"
          rules="required"
        >
          <b-form-group
            label="Item Name"
            :state="errors.length > 0 ? false : null"
          >
            <custom-v-select
              v-model="record.itemName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="itemOptions"
              label="text"
              placeholder="Select Item Name"
              :state="errors.length > 0 ? false:null"
              :reduce="option => option.id"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Remarks"
          :vid="`${validationId}_remarks`"
          rules="required"
        >
          <b-form-group
            label="Remarks"
          >
            <b-form-textarea
              v-model="record.remarks"
              :state="errors.length > 0 ? false:null"
              rows="1"
              placeholder="Remaks"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

    </b-row>
    <b-table-simple
      class="discrepancy-table"
      bordered
    >
      <b-thead>
        <b-tr>
          <b-th>#</b-th>
          <b-th>Quantity</b-th>
          <b-th>Batch</b-th>
          <b-th>MRP</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th>
            Invoice
          </b-th>
          <b-td>
            <validation-provider
              #default="{ errors }"
              name="Invoice Quantity"
              :vid="`${validationId}_total_quantiy`"
              rules="required"
            >
              <b-form-group>
                <b-form-input
                  v-model="record.totalQuantity"
                  :state="errors.length > 0 ? false:null"
                  type="number"
                  min="0"
                  placeholder="Invoice Quantity"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-td>
          <b-td>
            <validation-provider
              #default="{ errors }"
              name="Invoice Batch"
              :vid="`${validationId}_item_batch`"
              rules="required"
            >
              <b-form-group>
                <b-form-input
                  v-model="record.itemBatch"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Invoice Batch"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-td>
          <b-td>
            <validation-provider
              #default="{ errors }"
              name="Invoice MRP"
              :vid="`${validationId}_item_mrp`"
              rules="required"
            >
              <b-form-group>
                <b-form-input
                  v-model="record.itemMRP"
                  :state="errors.length > 0 ? false:null"
                  type="number"
                  min="0"
                  step="0.01"
                  placeholder="Invoice MRP"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea,
  BTableSimple, BTbody, BThead, BTr, BTh, BTd,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { isEqual, cloneDeep } from 'lodash'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    CustomVSelect,
    BFormTextarea,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTh,
    BTd,
  },
  props: {
    itemOptions: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    validationId: {
      type: String,
      required: true,
    },
    isBuyerTicket: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      record: {},
    }
  },
  computed: {
    out() {
      return cloneDeep(this.record)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.record = cloneDeep(this.value)
    },
  },
}
</script>
