<template>
  <div>
    <b-row>
      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Item Name"
          :vid="`${validationId}_item_name`"
          rules="required"
        >
          <b-form-group
            label="Item Name*"
            :state="errors.length > 0 ? false : null"
          >
            <custom-v-select
              v-model="record.itemName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="itemOptions"
              label="text"
              placeholder="Select Item Name"
              :state="errors.length > 0 ? false:null"
              :reduce="option => option.id"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="Old Batch"
          :vid="`${validationId}_old_batch`"
          rules=""
        >
          <b-form-group
            label="Old Batch"
            :state="errors.length > 0 || failedRules.anyOnePairRequired ? false : null"
          >
            <b-form-input
              v-model="record.oldBatch"
              :state="errors.length > 0 || failedRules.anyOnePairRequired ? false : null"
              placeholder="Old Batch"
            />
            <small class="text-danger">{{ errors[0] || failedRules.anyOnePairRequired }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="New Batch"
          :vid="`${validationId}_old_batch`"
          rules=""
        >
          <b-form-group
            label="New Batch"
            :state="errors.length > 0 || failedRules.anyOnePairRequired ? false : null"
          >
            <b-form-input
              v-model="record.newBatch"
              :state="errors.length > 0 || failedRules.anyOnePairRequired ? false:null"
              placeholder="New Batch"
            />
            <small class="text-danger">{{ errors[0] || failedRules.anyOnePairRequired }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="Old MRP"
          :vid="`${validationId}_old_mrp`"
          rules=""
        >
          <b-form-group
            label="Old MRP"
            :state="errors.length > 0 || failedRules.anyOnePairRequired ? false : null"
          >
            <b-form-input
              v-model="record.oldMrp"
              :state="errors.length > 0 || failedRules.anyOnePairRequired ? false:null"
              type="number"
              min="0"
              step="0.01"
              placeholder="Old MRP"
            />
            <small class="text-danger">{{ errors[0] || failedRules.anyOnePairRequired }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="New MRP"
          :vid="`${validationId}_new_mrp`"
          rules=""
        >
          <b-form-group
            label="New MRP"
            :state="errors.length > 0 || failedRules.anyOnePairRequired ? false : null"
          >
            <b-form-input
              v-model="record.newMrp"
              :state="errors.length > 0 || failedRules.anyOnePairRequired ? false:null"
              type="number"
              min="0"
              step="0.01"
              placeholder="New MRP"
            />
            <small class="text-danger">{{ errors[0] || failedRules.anyOnePairRequired }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="Old Expiry"
          :vid="`${validationId}_old_expiry`"
          rules=""
        >
          <b-form-group
            label="Old Expiry"
            :state="errors.length > 0 || failedRules.anyOnePairRequired ? false : null"
          >
            <b-form-input
              v-model="record.oldExpiry"
              :state="errors.length > 0 || failedRules.anyOnePairRequired ? false:null"
              type="month"
              placeholder="Old Expiry"
            />
            <small class="text-danger">{{ errors[0] || failedRules.anyOnePairRequired }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="New Expiry"
          :vid="`${validationId}_new_expiry`"
          rules=""
        >
          <b-form-group
            label="New Expiry"
            :state="errors.length > 0 || failedRules.anyOnePairRequired ? false : null"
          >
            <b-form-input
              v-model="record.newExpiry"
              :state="errors.length > 0 || failedRules.anyOnePairRequired ? false:null"
              type="month"
              placeholder="New Expiry"
            />
            <small class="text-danger">{{ errors[0] || failedRules.anyOnePairRequired }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Remarks"
          :vid="`${validationId}_remarks`"
          rules=""
        >
          <b-form-group
            label="Remarks"
          >
            <b-form-textarea
              v-model="record.remarks"
              :state="errors.length > 0 ? false:null"
              rows="1"
              placeholder="Remaks"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { isEqual, cloneDeep } from 'lodash'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    CustomVSelect,
    BFormTextarea,
  },
  props: {
    itemOptions: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    validationId: {
      type: String,
      required: true,
    },
    failedRules: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      record: {},
    }
  },
  computed: {
    out() {
      return cloneDeep(this.record)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.record = cloneDeep(this.value)
    },
  },
}
</script>
