<template>
  <b-tr>
    <b-td>{{ index + 1 }}</b-td>
    <b-td>{{ record.department }}</b-td>
    <b-td>{{ record.is_closed ? 'Closed' : 'Open' }}</b-td>
    <b-td>
      <template v-if="!record.closed_at">
        <b-form-textarea
          v-model="record.remarks"
          rows="1"
          placeholder="Remarks"
        />
      </template>
      <span v-else>{{ record.remarks }}</span>
    </b-td>
    <b-td>{{ record.closed_at ? formatedDate(record.closed_at, 'DD-MM-YYYY hh:mm:ss A') : '' }}</b-td>
    <b-td>{{ record.closed_by__fullname }}</b-td>
    <b-td>
      <template v-if="!record.is_closed">
        <b-button
          variant="primary"
          :disabled="updatingRemark || closingRemark"
          @click="updateRemark"
        >
          Update Remark
          <b-spinner
            v-if="updatingRemark"
            small
          />
        </b-button>
        <b-button
          class="mx-1"
          variant="danger"
          :disabled="updatingRemark || closingRemark"
          @click="displayCloseRemarkConfirmation = true"
        >
          Close Remark
          <b-spinner
            v-if="closingRemark"
            small
          />
        </b-button>
      </template>
    </b-td>

    <close-discrepancy-remark
      v-if="displayCloseRemarkConfirmation"
      :index="index"
      @modal-closed="displayCloseRemarkConfirmation = false"
      @submit="closeRemark"
    />
  </b-tr>
</template>

<script>
import axios from 'axios'
import {
  BTr, BTd, BFormTextarea, VBTooltip, BButton, BSpinner,
} from 'bootstrap-vue'
import { isEqual, cloneDeep } from 'lodash'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import dateMixin from '@/mixins/dateMixin'
import CloseDiscrepancyRemark from './CloseDiscrepancyRemark.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BTr, BTd, BFormTextarea, BButton, BSpinner, CloseDiscrepancyRemark,
  },
  mixins: [dateMixin],
  props: {
    discrepancyRemark: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isBuyerTicket: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      record: null,
      displayCloseRemarkConfirmation: false,
      updatingRemark: false,
      closingRemark: false,
    }
  },
  watch: {
    discrepancyRemark: {
      handler(val) {
        if (!isEqual(val, this.record)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.record = cloneDeep(this.discrepancyRemark)
    },
    async updateRemark() {
      this.updateDiscrepancyStatus()
    },
    async closeRemark() {
      this.updateDiscrepancyStatus(true)
    },
    async updateDiscrepancyStatus(isClosed = false) {
      try {
        this.closingRemark = isClosed
        this.updatingRemark = !isClosed
        let response
        if (this.isBuyerTicket) {
          response = await axios.post(`/tickets/update_buyer_discrepancy_remarks/${this.record.id}/`, {
            remarks: this.record.remarks,
            is_closed: isClosed,
          })
        } else {
          response = await axios.post(`/tickets/update_discrepancy_remarks/${this.record.id}/`, {
            remarks: this.record.remarks,
            is_closed: isClosed,
          })
        }

        this.closingRemark = false
        this.updatingRemark = false

        this.$toast({
          component: ToastificationContent,
          props: {
            title: isClosed ? 'Discrepancy remark closed successfully' : 'Discrepancy remark updated successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        if (isClosed) {
          this.$emit('onDiscrepancyClose')
        } else {
          this.record = response.data
        }
      } catch (error) {
        this.closingRemark = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.detail || isClosed ? error?.response?.data?.detail || 'Error closing discrepancy remark' : 'Error updating discrepancy remark',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

  },
}
</script>
