var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Old Item Name","vid":(_vm.validationId + "_old_item_name"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Old Item Name*","state":errors.length > 0 ? false : null}},[_c('custom-v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.itemOptions,"label":"text","placeholder":"Select Old Item Name","state":errors.length > 0 ? false:null,"reduce":function (option) { return option.id; }},model:{value:(_vm.record.oldItemName),callback:function ($$v) {_vm.$set(_vm.record, "oldItemName", $$v)},expression:"record.oldItemName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Old Item Quantity","vid":(_vm.validationId + "_old_item_quantiy"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Old Item Quantity*","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","min":"0","placeholder":"Old Item Quantity"},model:{value:(_vm.record.oldItemQty),callback:function ($$v) {_vm.$set(_vm.record, "oldItemQty", $$v)},expression:"record.oldItemQty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"New Item Name","vid":(_vm.validationId + "_new_item_name"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"New Item Name*","state":errors.length > 0 ? false : null}},[_c('custom-v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.itemOptions,"label":"text","placeholder":"Select New Item Name","state":errors.length > 0 ? false:null,"reduce":function (option) { return option.id; }},model:{value:(_vm.record.newItemName),callback:function ($$v) {_vm.$set(_vm.record, "newItemName", $$v)},expression:"record.newItemName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"New Item Quantity","vid":(_vm.validationId + "_new_item_quantiy"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"New Item Quantity*","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","min":"0","placeholder":"New Item Quantity"},model:{value:(_vm.record.newItemQty),callback:function ($$v) {_vm.$set(_vm.record, "newItemQty", $$v)},expression:"record.newItemQty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Remarks","vid":(_vm.validationId + "_remarks"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Remarks"}},[_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"rows":"1","placeholder":"Remaks"},model:{value:(_vm.record.remarks),callback:function ($$v) {_vm.$set(_vm.record, "remarks", $$v)},expression:"record.remarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }