<template>
  <b-card no-body>
    <b-card-header>
      <h4>Buyer Tickets</h4>
    </b-card-header>
    <b-card-body>
      <buyer-ticket-filters @filtersUpdated="filtersChangeHandler" />
      <buyer-ticket-list :filters="filters" />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody } from 'bootstrap-vue'
import BuyerTicketList from '@/components/Tickets/BuyerTickets/BuyerTicketList.vue'
import BuyerTicketFilters from '@/components/Tickets/BuyerTickets/BuyerTicketFilters.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BuyerTicketList,
    BuyerTicketFilters,
  },
  data() {
    return {
      filters: {},
    }
  },
  methods: {
    filtersChangeHandler(filterData) {
      this.filters = filterData
    },
  },
}
</script>
