<template>
  <div>
    <b-row>
      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Item Name"
          :vid="`${validationId}_item_name`"
          rules="required"
        >
          <b-form-group
            label="Item Name*"
            :state="errors.length > 0 ? false : null"
          >
            <custom-v-select
              v-model="record.itemName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="itemOptions"
              label="text"
              placeholder="Select Item Name"
              :state="errors.length > 0 ? false:null"
              :reduce="option => option.id"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="Current Bonus"
          :vid="`${validationId}_current_bonus`"
          rules="required"
        >
          <b-form-group
            label="Current Bonus*"
            :state="errors.length > 0 ? false : null"
          >
            <b-form-input
              v-model="record.currentBonus"
              :state="errors.length > 0 ? false:null"
              placeholder="Current Bonus"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="Desired Bonus"
          :vid="`${validationId}_desired_bonus`"
          rules="required"
        >
          <b-form-group
            label="Desired Bonus*"
            :state="errors.length > 0 ? false : null"
          >
            <b-form-input
              v-model="record.desiredBonus"
              :state="errors.length > 0 ? false:null"
              placeholder="Desired Bonus"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Bill No."
          :vid="`${validationId}_bill_no`"
          rules=""
        >
          <b-form-group
            label="Bill No."
            :state="errors.length > 0 ? false : null"
          >
            <custom-v-select
              v-model="record.billNo"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="billOptions"
              label="text"
              placeholder="Select Bill"
              :state="errors.length > 0 ? false:null"
              :reduce="option => option.text"
              @search="onBillSearch"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="Quantity"
          :vid="`${validationId}_quantity`"
          rules=""
        >
          <b-form-group
            label="Quantity"
            :state="errors.length > 0 ? false : null"
          >
            <b-form-input
              v-model="record.quantity"
              :state="errors.length > 0 ? false:null"
              type="number"
              min="0"
              placeholder="Quantity"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="Batch"
          :vid="`${validationId}_batch`"
          rules=""
        >
          <b-form-group
            label="Batch"
            :state="errors.length > 0 ? false:null"
          >
            <b-form-input
              v-model="record.batch"
              :state="errors.length > 0 ? false:null"
              placeholder="Batch"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="MRP"
          :vid="`${validationId}_mrp`"
          rules=""
        >
          <b-form-group
            label="MRP"
            :state="errors.length > 0 ? false : null"
          >
            <b-form-input
              v-model="record.mrp"
              :state="errors.length > 0 ? false:null"
              type="number"
              min="0"
              step="0.01"
              placeholder="MRP"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Remarks"
          :vid="`${validationId}_remarks`"
          rules=""
        >
          <b-form-group
            label="Remarks"
          >
            <b-form-textarea
              v-model="record.remarks"
              :state="errors.length > 0 ? false:null"
              rows="1"
              placeholder="Remaks"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import axios from 'axios'
import { isEqual, cloneDeep, debounce } from 'lodash'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    CustomVSelect,
    BFormTextarea,
  },
  props: {
    itemOptions: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    validationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      record: {},
      billOptions: [],
    }
  },
  computed: {
    out() {
      return cloneDeep(this.record)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
    this.fetchBillOptions()
  },
  methods: {
    setInternalState() {
      this.record = cloneDeep(this.value)
    },

    async fetchBillOptions() {
      const res = await axios.get('/accounts/bill_options')
      this.billOptions = res.data.data
    },

    onBillSearch: debounce(function dummyName(search, loading) {
      this.refreshBillOptions(search, loading)
    }, 500),

    refreshBillOptions(search, loading) {
      loading(true)
      axios.get('/accounts/bill_options', {
        params: {
          search_term: search,
        },
      }).then(res => {
        this.billOptions = res.data.data
        loading(false)
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.detail || 'Error fetching bill options',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        loading(false)
      })
    },
  },
}
</script>
