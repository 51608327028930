var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Invoice Item No.","vid":(_vm.validationId + "_invoice_item_no"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Invoice Item No."}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","min":"0","placeholder":"Invoice Item No."},model:{value:(_vm.record.invoiceItemNumber),callback:function ($$v) {_vm.$set(_vm.record, "invoiceItemNumber", $$v)},expression:"record.invoiceItemNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Item Name","vid":(_vm.validationId + "_item_name"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Item Name","state":errors.length > 0 ? false : null}},[_c('custom-v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.itemOptions,"label":"text","placeholder":"Select Item Name","state":errors.length > 0 ? false:null,"reduce":function (option) { return option.id; }},model:{value:(_vm.record.itemName),callback:function ($$v) {_vm.$set(_vm.record, "itemName", $$v)},expression:"record.itemName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('div',{staticClass:"discrepancy-table-wrapper overflow-auto"},[_c('b-table-simple',{staticClass:"discrepancy-table",attrs:{"hover":"","bordered":""}},[_c('col',{attrs:{"width":"150px"}}),_c('col',{attrs:{"width":"350px"}}),_vm._l((_vm.record.receivedItems),function(receivedItem,receivedItemIndex){return _c('col',{key:receivedItemIndex,attrs:{"width":"350px"}})}),_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v(" # ")]),_c('b-th',[_vm._v(" INVOICE ")]),_vm._l((_vm.record.receivedItems),function(receivedItem,receivedItemIndex){return _c('b-th',{key:receivedItemIndex},[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',[_vm._v(" Physical #"+_vm._s(receivedItemIndex + 1)+" ")]),_c('div',{staticClass:"mx-1"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({boundary:'window'}),expression:"{boundary:'window'}",modifiers:{"hover":true}}],staticClass:"cursor-pointer ",attrs:{"title":"Delete","icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.deleteReceivedItem(receivedItemIndex)}}})],1)])])})],2)],1),_c('b-tbody',[_c('b-tr',[_c('b-th',[_vm._v(" QUANTITY ")]),_c('b-td',[_c('validation-provider',{attrs:{"name":"Invoice Quantity","vid":(_vm.validationId + "_invoice_quantiy"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.failedRules.matchInvoiceAndPhysicalQuantity ? false : null,"type":"number","min":"0","placeholder":"Invoice Quantity"},model:{value:(_vm.record.invoiceQuantity),callback:function ($$v) {_vm.$set(_vm.record, "invoiceQuantity", $$v)},expression:"record.invoiceQuantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.failedRules.matchInvoiceAndPhysicalQuantity))])],1)]}}])})],1),_vm._l((_vm.record.receivedItems),function(receivedItem,receivedItemIndex){return _c('b-td',{key:receivedItemIndex},[_c('validation-provider',{attrs:{"name":"Physical Quantity","vid":(_vm.validationId + "_ri_" + receivedItemIndex + "_physical_quantiy"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{style:({'color': (_vm.record.receivedItems[receivedItemIndex].physicalQuantity == _vm.record.invoiceQuantity) ? '#008000': '#FF0000'}),attrs:{"state":errors.length > 0 || _vm.failedRules.matchInvoiceAndPhysicalQuantity ? false : null,"type":"number","min":"0","placeholder":"Physical Quantity"},model:{value:(_vm.record.receivedItems[receivedItemIndex].physicalQuantity),callback:function ($$v) {_vm.$set(_vm.record.receivedItems[receivedItemIndex], "physicalQuantity", $$v)},expression:"record.receivedItems[receivedItemIndex].physicalQuantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.failedRules.matchInvoiceAndPhysicalQuantity))])],1)]}}],null,true)})],1)})],2),_c('b-tr',[_c('b-th',[_vm._v(" BATCH ")]),_c('b-td',[_c('validation-provider',{attrs:{"name":"Invoice Batch","vid":(_vm.validationId + "_invoice_batch"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Invoice Batch"},model:{value:(_vm.record.invoiceBatch),callback:function ($$v) {_vm.$set(_vm.record, "invoiceBatch", $$v)},expression:"record.invoiceBatch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_vm._l((_vm.record.receivedItems),function(receivedItem,receivedItemIndex){return _c('b-td',{key:receivedItemIndex},[_c('validation-provider',{attrs:{"name":"Physical Batch","vid":(_vm.validationId + "_ri_" + receivedItemIndex + "_physical_batch"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{style:({'color': (_vm.record.receivedItems[receivedItemIndex].physicalBatch == _vm.record.invoiceBatch) ? '#008000': '#FF0000'}),attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Physical Batch"},model:{value:(_vm.record.receivedItems[receivedItemIndex].physicalBatch),callback:function ($$v) {_vm.$set(_vm.record.receivedItems[receivedItemIndex], "physicalBatch", $$v)},expression:"record.receivedItems[receivedItemIndex].physicalBatch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)})],2),_c('b-tr',[_c('b-th',[_vm._v(" EXPIRY ")]),_c('b-td',[_c('validation-provider',{attrs:{"name":"Invoice Expiry","vid":(_vm.validationId + "_invoice_expiry"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"month","placeholder":"Invoice Expiry"},model:{value:(_vm.record.invoiceExpiry),callback:function ($$v) {_vm.$set(_vm.record, "invoiceExpiry", $$v)},expression:"record.invoiceExpiry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_vm._l((_vm.record.receivedItems),function(receivedItem,receivedItemIndex){return _c('b-td',{key:receivedItemIndex},[_c('validation-provider',{attrs:{"name":"Physical Expiry","vid":(_vm.validationId + "_ri_" + receivedItemIndex + "_physical_expiry"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{style:({'color': (_vm.record.receivedItems[receivedItemIndex].physicalExpiry == _vm.record.invoiceExpiry) ? '#008000': '#FF0000'}),attrs:{"state":errors.length > 0 ? false:null,"type":"month","placeholder":"Physical Expiry"},model:{value:(_vm.record.receivedItems[receivedItemIndex].physicalExpiry),callback:function ($$v) {_vm.$set(_vm.record.receivedItems[receivedItemIndex], "physicalExpiry", $$v)},expression:"record.receivedItems[receivedItemIndex].physicalExpiry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)})],2),_c('b-tr',[_c('b-th',[_vm._v(" MRP ")]),_c('b-td',[_c('validation-provider',{attrs:{"name":"Invoice MRP","vid":(_vm.validationId + "_invoice_mrp"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","min":"0","step":"0.01","placeholder":"Invoice MRP"},model:{value:(_vm.record.invoiceMRP),callback:function ($$v) {_vm.$set(_vm.record, "invoiceMRP", $$v)},expression:"record.invoiceMRP"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_vm._l((_vm.record.receivedItems),function(receivedItem,receivedItemIndex){return _c('b-td',{key:receivedItemIndex},[_c('validation-provider',{attrs:{"name":"Physical MRP","vid":(_vm.validationId + "_ri_" + receivedItemIndex + "_physical_mrp"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{style:({'color': (_vm.record.receivedItems[receivedItemIndex].physicalMRP == _vm.record.invoiceMRP) ? '#008000': '#FF0000'}),attrs:{"state":errors.length > 0 ? false:null,"type":"number","min":"0","step":"0.01","placeholder":"Physical MRP"},model:{value:(_vm.record.receivedItems[receivedItemIndex].physicalMRP),callback:function ($$v) {_vm.$set(_vm.record.receivedItems[receivedItemIndex], "physicalMRP", $$v)},expression:"record.receivedItems[receivedItemIndex].physicalMRP"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)})],2)],1)],2)],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({boundary:'window'}),expression:"{boundary:'window'}",modifiers:{"hover":true}}],staticClass:"my-1",attrs:{"variant":"primary","title":"Add Recieved Item"},on:{"click":_vm.addReceivedItem}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"18"}}),_vm._v(" Add ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }