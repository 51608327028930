<template>
  <div>
    <b-row>
      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Old Party Name"
          :vid="`${validationId}_old_party_name`"
          rules="required"
        >
          <b-form-group
            label="Old Party Name*"
            :state="errors.length > 0 ? false : null"
          >
            <custom-v-select
              v-model="record.oldPartyName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="partyOptions"
              label="text"
              placeholder="Select Old Party Name"
              :state="errors.length > 0 ? false:null"
              :reduce="option => option.text"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="New Party Name"
          :vid="`${validationId}_new_party_name`"
          rules="required"
        >
          <b-form-group
            label="New Party Name*"
            :state="errors.length > 0 ? false : null"
          >
            <custom-v-select
              v-model="record.newPartyName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="partyOptions"
              label="text"
              placeholder="Select New Party Name"
              :state="errors.length > 0 ? false:null"
              :reduce="option => option.text"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Remarks"
          :vid="`${validationId}_remarks`"
          rules="required"
        >
          <b-form-group
            label="Remarks*"
          >
            <b-form-textarea
              v-model="record.remarks"
              :state="errors.length > 0 ? false:null"
              rows="1"
              placeholder="Remaks"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { isEqual, cloneDeep } from 'lodash'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    ValidationProvider,
    CustomVSelect,
    BFormTextarea,
  },
  props: {
    partyOptions: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    validationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      record: {},
    }
  },
  computed: {
    out() {
      return cloneDeep(this.record)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.record = cloneDeep(this.value)
    },
  },
}
</script>
