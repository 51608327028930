<template>
  <div>
    <b-row>
      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Invoice Item No."
          :vid="`${validationId}_invoice_item_no`"
          rules="required"
        >
          <b-form-group
            label="Invoice Item No."
          >
            <b-form-input
              v-model="record.invoiceItemNumber"
              :state="errors.length > 0 ? false:null"
              type="number"
              min="0"
              placeholder="Invoice Item No."
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Item Name"
          :vid="`${validationId}_item_name`"
          rules="required"
        >
          <b-form-group
            label="Item Name"
            :state="errors.length > 0 ? false : null"
          >
            <custom-v-select
              v-model="record.itemName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="itemOptions"
              label="text"
              placeholder="Select Item Name"
              :state="errors.length > 0 ? false:null"
              :reduce="option => option.id"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <div class="discrepancy-table-wrapper">
      <b-table-simple
        hover
        bordered
        class="discrepancy-table"
      >
        <col width="150px">
        <col width="350px">
        <col
          v-for="(receivedItem, receivedItemIndex) of record.receivedItems"
          :key="receivedItemIndex"
          width="350px"
        >
        <b-thead>
          <b-tr>
            <b-th>
              #
            </b-th>
            <b-th>
              INVOICE
            </b-th>
            <b-th
              v-for="(receivedItem, receivedItemIndex) of record.receivedItems"
              :key="receivedItemIndex"
            >
              <div class="d-flex align-items-center justify-content-center">
                <div>Physical  #{{ receivedItemIndex + 1 }}</div>
                <div class="mx-1">
                  <feather-icon
                    v-b-tooltip.hover="{boundary:'window'}"
                    title="Delete"
                    icon="Trash2Icon"
                    size="18"
                    class="cursor-pointer "
                    @click="deleteReceivedItem(receivedItemIndex)"
                  />
                </div>
              </div>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-th>
              ITEM NAME
            </b-th>
            <b-td />

            <b-td
              v-for="(receivedItem, receivedItemIndex) of record.receivedItems"
              :key="receivedItemIndex"
            >
              <validation-provider
                #default="{ errors }"
                name="Item Name"
                :vid="`${validationId}_ri_${receivedItemIndex}_item_name`"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false : null"
                >
                  <custom-v-select
                    v-model="record.receivedItems[receivedItemIndex].itemName"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="itemOptions"
                    label="text"
                    placeholder="Select Item Name"
                    :state="errors.length > 0 ? false:null"
                    :reduce="option => option.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              QUANTITY
            </b-th>
            <b-td>
              <validation-provider
                #default="{ errors }"
                name="Invoice Quantity"
                :vid="`${validationId}_invoice_quantiy`"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="record.invoiceQuantity"
                    :state="errors.length > 0 || failedRules.matchInvoiceAndPhysicalQuantity ? false : null"
                    type="number"
                    min="0"
                    placeholder="Invoice Quantity"
                  />
                  <small class="text-danger">{{ errors[0] || failedRules.matchInvoiceAndPhysicalQuantity }}</small>
                </b-form-group>
              </validation-provider>
            </b-td>
            <b-td
              v-for="(receivedItem, receivedItemIndex) of record.receivedItems"
              :key="receivedItemIndex"
            >

              <validation-provider
                #default="{ errors }"
                name="Physical Quantity"
                :vid="`${validationId}_ri_${receivedItemIndex}_physical_quantiy`"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="record.receivedItems[receivedItemIndex].physicalQuantity"
                    :state="errors.length > 0 || failedRules.matchInvoiceAndPhysicalQuantity ? false : null"
                    type="number"
                    min="0"
                    placeholder="Physical Quantity"
                    :style="{'color': (record.receivedItems[receivedItemIndex].physicalQuantity == record.invoiceQuantity) ? '#008000': '#FF0000'}"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] || failedRules.matchInvoiceAndPhysicalQuantity }}</small>
                </b-form-group>
              </validation-provider>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              BATCH
            </b-th>
            <b-td>
              <validation-provider
                #default="{ errors }"
                name="Invoice Batch"
                :vid="`${validationId}_invoice_batch`"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="record.invoiceBatch"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Invoice Batch"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-td>
            <b-td
              v-for="(receivedItem, receivedItemIndex) of record.receivedItems"
              :key="receivedItemIndex"
            >
              <validation-provider
                #default="{ errors }"
                name="Physical Batch"
                :vid="`${validationId}_ri_${receivedItemIndex}_physical_batch`"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="record.receivedItems[receivedItemIndex].physicalBatch"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Physical Batch"
                    :style="{'color': (record.receivedItems[receivedItemIndex].physicalBatch == record.invoiceBatch) ? '#008000': '#FF0000'}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              EXPIRY
            </b-th>
            <b-td>
              <validation-provider
                #default="{ errors }"
                name="Invoice Expiry"
                :vid="`${validationId}_invoice_expiry`"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="record.invoiceExpiry"
                    :state="errors.length > 0 ? false:null"
                    type="month"
                    placeholder="Invoice Expiry"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-td>
            <b-td
              v-for="(receivedItem, receivedItemIndex) of record.receivedItems"
              :key="receivedItemIndex"
            >
              <validation-provider
                #default="{ errors }"
                name="Physical Expiry"
                :vid="`${validationId}_ri_${receivedItemIndex}_physical_expiry`"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="record.receivedItems[receivedItemIndex].physicalExpiry"
                    :state="errors.length > 0 ? false:null"
                    type="month"
                    placeholder="Physical Expiry"
                    :style="{'color': (record.receivedItems[receivedItemIndex].physicalExpiry == record.invoiceExpiry) ? '#008000': '#FF0000'}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              MRP
            </b-th>
            <b-td>
              <validation-provider
                #default="{ errors }"
                name="Invoice MRP"
                :vid="`${validationId}_invoice_mrp`"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="record.invoiceMRP"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder="Invoice MRP"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-td>
            <b-td
              v-for="(receivedItem, receivedItemIndex) of record.receivedItems"
              :key="receivedItemIndex"
            >
              <validation-provider
                #default="{ errors }"
                name="Physical MRP"
                :vid="`${validationId}_ri_${receivedItemIndex}_physical_mrp`"
                rules="required"
              >
                <b-form-group>
                  <b-form-input
                    v-model="record.receivedItems[receivedItemIndex].physicalMRP"
                    :state="errors.length > 0 ? false:null"
                    type="number"
                    min="0"
                    step="0.01"
                    placeholder="Physical MRP"
                    :style="{'color': (record.receivedItems[receivedItemIndex].physicalMRP == record.invoiceMRP) ? '#008000': '#FF0000'}"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>

    <b-button
      v-b-tooltip.hover="{boundary:'window'}"
      variant="primary"
      title="Add Recieved Item"
      class="my-1"
      @click="addReceivedItem"
    >
      <feather-icon
        icon="PlusIcon"
        size="18"
      /> Add
    </b-button>
  </div>
</template>

<script>

import {
  BRow, BCol, BButton, BFormGroup, BFormInput, VBTooltip,
  BTableSimple, BTbody, BThead, BTr, BTh, BTd,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { isEqual, cloneDeep } from 'lodash'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import {
  itemExchangedReceivedItemDefaultValues as receivedItemDefaultValues,
} from '@/components/Tickets/TicketDiscrepancy/discrepancy-types'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BFormInput,
    ValidationProvider,
    CustomVSelect,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTh,
    BTd,
  },
  props: {
    itemOptions: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    validationId: {
      type: String,
      required: true,
    },
    failedRules: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      record: {},
    }
  },
  computed: {
    out() {
      return cloneDeep(this.record)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.record = cloneDeep(this.value)
    },
    addReceivedItem() {
      const receivedItem = { ...receivedItemDefaultValues }
      receivedItem.physicalBatch = this.record.invoiceBatch
      receivedItem.physicalExpiry = this.record.invoiceExpiry
      receivedItem.physicalMRP = this.record.invoiceMRP
      receivedItem.physicalQuantity = this.record.invoiceQuantity

      this.record.receivedItems.push(receivedItem)
    },
    deleteReceivedItem(index) {
      this.record.receivedItems.splice(index, 1)
    },
  },
}
</script>
