<template>
  <div>
    <h5>Discrepancies ({{ items.length }})</h5>
    <div
      v-for="(discrepancy, discrepancyIndex) of items"
      :key="discrepancyIndex"
    >
      <div
        class="d-flex align-items-center my-1"
        style="column-gap: 10px;"
      >
        <h5 class="mb-0">
          {{ discrepancyIndex + 1 }}.  {{ discrepancy.type }}
        </h5>
        <div style="margin-bottom:4px;">
          <feather-icon
            v-b-tooltip.hover="{boundary:'window'}"
            title="Delete"
            icon="Trash2Icon"
            size="18"
            class="cursor-pointer"
            @click="deleteDiscrepancy(discrepancyIndex)"
          />
        </div>
      </div>

      <div>
        <item-short-discrepancy
          v-if="discrepancy.type === 'Item Short'"
          v-model="items[discrepancyIndex].details"
          :item-options="itemOptions"
          :is-buyer-ticket="isBuyerTicket"
          :validation-id="`ticket_discrepancy_${discrepancyIndex}`"
        />
        <item-extra-discrepancy
          v-if="discrepancy.type === 'Item Extra'"
          v-model="items[discrepancyIndex].details"
          :item-options="itemOptions"
          :validation-id="`ticket_discrepancy_${discrepancyIndex}`"
        />
        <item-damage-discrepancy
          v-if="discrepancy.type === 'Item Damage'"
          v-model="items[discrepancyIndex].details"
          :item-options="itemOptions"
          :validation-id="`ticket_discrepancy_${discrepancyIndex}`"
        />
        <bonus-problem-discrepancy
          v-if="discrepancy.type === 'Bonus Problem'"
          v-model="items[discrepancyIndex].details"
          :item-options="itemOptions"
          :is-buyer-ticket="isBuyerTicket"
          :validation-id="`ticket_discrepancy_${discrepancyIndex}`"
        />
        <bonus-correction-discrepancy
          v-if="discrepancy.type === 'Bonus Correction'"
          v-model="items[discrepancyIndex].details"
          :item-options="itemOptions"
          :is-buyer-ticket="isBuyerTicket"
          :validation-id="`ticket_discrepancy_${discrepancyIndex}`"
        />
        <validation-provider
          v-if="discrepancy.type === 'Bill Modification'"
          #default="{ errors, failedRules }"
          :key="`ticket_discrepancy_${discrepancyIndex}`"
          name="Bill Modification Discrepancy"
          :vid="`ticket_discrepancy_${discrepancyIndex}`"
          mode="eager"
          rules="shouldHaveAtleastOneSubItem"
        >
          <bill-modification-discrepancy
            v-model="items[discrepancyIndex].details"
            :item-options="itemOptions"
            :party-options="partyOptions"
            :is-buyer-ticket="isBuyerTicket"
            :validation-id="`ticket_discrepancy_${discrepancyIndex}`"
            :failed-rules="failedRules"
            :parent-index="discrepancyIndex + 1"
          />
          <b-alert
            variant="danger"
            :show="errors && errors[0] ? true : false"
            class="my-1"
          >
            <div class="alert-body">
              <p>
                {{ errors[0] }}
              </p>
            </div>
          </b-alert>
        </validation-provider>

        <sales-return-discrepancy
          v-if="discrepancy.type === 'Sales Return'"
          v-model="items[discrepancyIndex].details"
          :item-options="itemOptions"
          :is-buyer-ticket="isBuyerTicket"
          :validation-id="`ticket_discrepancy_${discrepancyIndex}`"
        />

        <exp-brk-return-discrepancy
          v-if="discrepancy.type === 'Exp/Brk Return'"
          v-model="items[discrepancyIndex].details"
          :item-options="itemOptions"
          :is-buyer-ticket="isBuyerTicket"
          :validation-id="`ticket_discrepancy_${discrepancyIndex}`"
        />

        <bulk-return-discrepancy
          v-if="discrepancy.type === 'Bulk Return'"
          v-model="items[discrepancyIndex].details"
          :item-options="itemOptions"
          :is-buyer-ticket="isBuyerTicket"
          :validation-id="`ticket_discrepancy_${discrepancyIndex}`"
        />

        <other-problem-discrepancy
          v-if="discrepancy.type === 'Other Problem'"
          v-model="items[discrepancyIndex].details"
          :item-options="itemOptions"
          :is-buyer-ticket="isBuyerTicket"
          :validation-id="`ticket_discrepancy_${discrepancyIndex}`"
        />
        <PODSupplyDiscrepancy
          v-if="discrepancy.type === 'POD Supply'"
          v-model="items[discrepancyIndex].details"
        />

        <semi-or-export-discrepancy
          v-if="discrepancy.type === 'Semi/Export'"
          v-model="items[discrepancyIndex].details"
        />

        <validation-provider
          v-if="discrepancy.type === 'Batch Problem'"
          #default="{ errors, failedRules }"
          :key="`ticket_discrepancy_${discrepancyIndex}`"
          name="Batch Problem Discrepancy"
          :vid="`ticket_discrepancy_${discrepancyIndex}`"
          mode="eager"
          rules="shouldHaveAtleastOneReceivedItem|matchInvoiceAndPhysicalQuantity"
        >
          <batch-problem-discrepancy
            v-model="items[discrepancyIndex].details"
            :item-options="itemOptions"
            :validation-id="`ticket_discrepancy_${discrepancyIndex}`"
            :failed-rules="failedRules"
          />
          <b-alert
            variant="danger"
            :show="errors && errors[0] ? true : false"
            class="my-1"
          >
            <div class="alert-body">
              <p>
                {{ errors[0] }}
              </p>
            </div>
          </b-alert>
        </validation-provider>

        <validation-provider
          v-if="discrepancy.type === 'Item Exchanged'"
          #default="{ errors, failedRules }"
          :key="`ticket_discrepancy_${discrepancyIndex}`"
          name="Item Exchanged Discrepancy"
          :vid="`ticket_discrepancy_${discrepancyIndex}`"
          mode="eager"
          rules="shouldHaveAtleastOneReceivedItem|matchInvoiceAndPhysicalQuantity"
        >
          <item-exchanged-discrepancy
            v-model="items[discrepancyIndex].details"
            :item-options="itemOptions"
            :validation-id="`ticket_discrepancy_${discrepancyIndex}`"
            :failed-rules="failedRules"
          />
          <b-alert
            variant="danger"
            :show="errors && errors[0] ? true : false"
            class="my-1"
          >
            <div class="alert-body">
              <p>
                {{ errors[0] }}
              </p>
            </div>
          </b-alert>
        </validation-provider>
      </div>
      <hr>
    </div>

    <b-row>
      <b-col
        md="3"
        class="mb-1"
      >
        <custom-v-select
          v-model="discrepancyType"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          placeholder="Select Discrepancy Type"
          label="label"
          :options="discrepancyTypes.map(discrepancyType => discrepancyType.name)"
          :clearable="false"
        />
      </b-col>
      <b-col class="mb-1">
        <b-button
          variant="outline-primary"
          :disabled="!discrepancyType"
          @click="addDiscrepancy"
        >
          Add Discrepancy
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BButton, VBTooltip, BAlert,
} from 'bootstrap-vue'
import { isEqual, cloneDeep } from 'lodash'
import { ValidationProvider } from 'vee-validate'

// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import { discrepancyTypes as supplierDiscrepancy, buyerDiscrepancyTypes as buyerDiscrepancy } from '@/components/Tickets/TicketDiscrepancy/discrepancy-types'
import ItemShortDiscrepancy from './ItemShortDiscrepancy.vue'
import ItemExtraDiscrepancy from './ItemExtraDiscrepancy.vue'
import ItemDamageDiscrepancy from './ItemDamageDiscrepancy.vue'
import BatchProblemDiscrepancy from './BatchProblemDiscrepancy.vue'
import ItemExchangedDiscrepancy from './ItemExchangedDiscrepancy.vue'
import BonusProblemDiscrepancy from './BonusProblemDiscrepancy.vue'
import BonusCorrectionDiscrepancy from './BonusCorrectionDiscrepancy.vue'
import BillModificationDiscrepancy from './BillModificationDiscrepancy.vue'
import SalesReturnDiscrepancy from './SalesReturnDiscrepancy.vue'
import ExpBrkReturnDiscrepancy from './ExpBrkReturnDiscrepancy.vue'
import BulkReturnDiscrepancy from './BulkReturnDiscrepancy.vue'
import OtherProblemDiscrepancy from './OtherProblemDiscrepancy.vue'
import PODSupplyDiscrepancy from './PODSupplyDiscrepancy.vue'
import SemiOrExportDiscrepancy from './SemiOrExportDiscrepancy.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BButton,
    CustomVSelect,
    ItemShortDiscrepancy,
    ItemExtraDiscrepancy,
    ItemDamageDiscrepancy,
    BatchProblemDiscrepancy,
    ItemExchangedDiscrepancy,
    BonusProblemDiscrepancy,
    OtherProblemDiscrepancy,
    ValidationProvider,
    BAlert,
    PODSupplyDiscrepancy,
    SemiOrExportDiscrepancy,
    BonusCorrectionDiscrepancy,
    SalesReturnDiscrepancy,
    ExpBrkReturnDiscrepancy,
    BulkReturnDiscrepancy,
    BillModificationDiscrepancy,
  },
  props: {
    itemOptions: {
      type: Array,
      required: true,
    },
    partyOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    value: {
      type: Array,
      required: true,
    },
    isBuyerTicket: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      discrepancyType: null,
      items: [],
    }
  },
  computed: {
    out() {
      return cloneDeep(this.items)
    },
    discrepancyTypes() {
      if (this.isBuyerTicket) {
        return buyerDiscrepancy
      }

      return supplierDiscrepancy
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.items = cloneDeep(this.value)
    },
    addDiscrepancy() {
      const discrepancyTypeRecord = this.discrepancyTypes.find(discrepancyType => discrepancyType.name === this.discrepancyType)
      const descrepancy = {
        type: this.discrepancyType,
        details: cloneDeep(discrepancyTypeRecord.defaultData),
        discrepancy_remarks: [],
      }

      if (this.$props.isBuyerTicket) {
        delete descrepancy.details.invoiceItemNumber
      }
      this.items.push(descrepancy)
    },
    deleteDiscrepancy(index) {
      this.items.splice(index, 1)
    },
  },
}
</script>
