<template>
  <b-modal
    v-model="showModal"
    title="Close Remark"
    centered
    @hidden="$emit('modal-closed')"
    @ok="submitHandler"
  >
    <b-card-text>
      <p>Are you sure you want to close remark #{{ index + 1 }}?</p>
      <p>Once closed, it can not be edited!</p>
    </b-card-text>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        @click="cancel()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        @click="ok()"
      >
        Close Remark
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BCardText, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BCardText,
    BButton,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
    }
  },
  methods: {
    submitHandler(event) {
      event.preventDefault()
      this.$emit('submit')
      this.showModal = false
    },
  },
}
</script>
