var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Item Name","vid":(_vm.validationId + "_item_name"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Item Name*","state":errors.length > 0 ? false : null}},[_c('custom-v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.itemOptions,"label":"text","placeholder":"Select Item Name","state":errors.length > 0 ? false:null,"reduce":function (option) { return option.id; }},model:{value:(_vm.record.itemName),callback:function ($$v) {_vm.$set(_vm.record, "itemName", $$v)},expression:"record.itemName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Old Batch","vid":(_vm.validationId + "_old_batch"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Old Batch","state":errors.length > 0 || _vm.failedRules.anyOnePairRequired ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.failedRules.anyOnePairRequired ? false : null,"placeholder":"Old Batch"},model:{value:(_vm.record.oldBatch),callback:function ($$v) {_vm.$set(_vm.record, "oldBatch", $$v)},expression:"record.oldBatch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.failedRules.anyOnePairRequired))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"New Batch","vid":(_vm.validationId + "_old_batch"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"New Batch","state":errors.length > 0 || _vm.failedRules.anyOnePairRequired ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.failedRules.anyOnePairRequired ? false:null,"placeholder":"New Batch"},model:{value:(_vm.record.newBatch),callback:function ($$v) {_vm.$set(_vm.record, "newBatch", $$v)},expression:"record.newBatch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.failedRules.anyOnePairRequired))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Old MRP","vid":(_vm.validationId + "_old_mrp"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Old MRP","state":errors.length > 0 || _vm.failedRules.anyOnePairRequired ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.failedRules.anyOnePairRequired ? false:null,"type":"number","min":"0","step":"0.01","placeholder":"Old MRP"},model:{value:(_vm.record.oldMrp),callback:function ($$v) {_vm.$set(_vm.record, "oldMrp", $$v)},expression:"record.oldMrp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.failedRules.anyOnePairRequired))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"New MRP","vid":(_vm.validationId + "_new_mrp"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"New MRP","state":errors.length > 0 || _vm.failedRules.anyOnePairRequired ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.failedRules.anyOnePairRequired ? false:null,"type":"number","min":"0","step":"0.01","placeholder":"New MRP"},model:{value:(_vm.record.newMrp),callback:function ($$v) {_vm.$set(_vm.record, "newMrp", $$v)},expression:"record.newMrp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.failedRules.anyOnePairRequired))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Old Expiry","vid":(_vm.validationId + "_old_expiry"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Old Expiry","state":errors.length > 0 || _vm.failedRules.anyOnePairRequired ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.failedRules.anyOnePairRequired ? false:null,"type":"month","placeholder":"Old Expiry"},model:{value:(_vm.record.oldExpiry),callback:function ($$v) {_vm.$set(_vm.record, "oldExpiry", $$v)},expression:"record.oldExpiry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.failedRules.anyOnePairRequired))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"New Expiry","vid":(_vm.validationId + "_new_expiry"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"New Expiry","state":errors.length > 0 || _vm.failedRules.anyOnePairRequired ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 || _vm.failedRules.anyOnePairRequired ? false:null,"type":"month","placeholder":"New Expiry"},model:{value:(_vm.record.newExpiry),callback:function ($$v) {_vm.$set(_vm.record, "newExpiry", $$v)},expression:"record.newExpiry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] || _vm.failedRules.anyOnePairRequired))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Remarks","vid":(_vm.validationId + "_remarks"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Remarks"}},[_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"rows":"1","placeholder":"Remaks"},model:{value:(_vm.record.remarks),callback:function ($$v) {_vm.$set(_vm.record, "remarks", $$v)},expression:"record.remarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }