<template>
  <div>
    <b-row>
      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Bill No."
          :vid="`${validationId}_bill_no`"
          rules="required"
        >
          <b-form-group
            label="Bill No.*"
            :state="errors.length > 0 ? false : null"
          >
            <custom-v-select
              v-model="record.billNo"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="billOptions"
              label="text"
              placeholder="Select Bill"
              :state="errors.length > 0 ? false:null"
              :reduce="option => option.text"
              @search="onBillSearch"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <div class="sub-discrepancy-wrapper">

      <h6>SubDiscrepancies ({{ record.items.length }})</h6>
      <div
        v-for="(discrepancy, discrepancyIndex) of record.items"
        :key="discrepancyIndex"
      >
        <div
          class="d-flex align-items-center my-1"
          style="column-gap: 10px;"
        >
          <h5 class="mb-0">
            {{ parentIndex }}.{{ discrepancyIndex + 1 }} :  {{ discrepancy.type }}
          </h5>
          <div style="margin-bottom:4px;">
            <feather-icon
              v-b-tooltip.hover="{boundary:'window'}"
              title="Delete"
              icon="Trash2Icon"
              size="18"
              class="cursor-pointer"
              @click="deleteDiscrepancy(discrepancyIndex)"
            />
          </div>
        </div>

        <div>
          <item-exchange
            v-if="discrepancy.type === 'Item Exchange'"
            v-model="record.items[discrepancyIndex].details"
            :item-options="itemOptions"
            :validation-id="`${validationId}_${discrepancyIndex}`"
          />

          <quantity-problem
            v-if="discrepancy.type === 'Quantity Problem'"
            v-model="record.items[discrepancyIndex].details"
            :item-options="itemOptions"
            :validation-id="`${validationId}_${discrepancyIndex}`"
          />

          <rate-problem
            v-if="discrepancy.type === 'Rate Problem'"
            v-model="record.items[discrepancyIndex].details"
            :item-options="itemOptions"
            :validation-id="`${validationId}_${discrepancyIndex}`"
          />

          <discount-problem
            v-if="discrepancy.type === 'Discount Problem'"
            v-model="record.items[discrepancyIndex].details"
            :validation-id="`${validationId}_${discrepancyIndex}`"
          />

          <party-name
            v-if="discrepancy.type === 'Party Name'"
            v-model="record.items[discrepancyIndex].details"
            :party-options="partyOptions"
            :validation-id="`${validationId}_${discrepancyIndex}`"
          />

          <mode-change
            v-if="discrepancy.type === 'Mode Change'"
            v-model="record.items[discrepancyIndex].details"
            :validation-id="`${validationId}_${discrepancyIndex}`"
          />

          <validation-provider
            v-if="discrepancy.type === 'Batch/MRP/Exp Problem'"
            #default="{ errors, failedRules }"
            :key="`${validationId}_${discrepancyIndex}_main`"
            name="Batch/MRP/Exp Problem"
            :vid="`${validationId}_${discrepancyIndex}_main`"
            mode="eager"
            rules="anyOnePairRequired"
          >
            <batch-mrp-exp-problem
              v-model="record.items[discrepancyIndex].details"
              :item-options="itemOptions"
              :validation-id="`${validationId}_${discrepancyIndex}`"
              :failed-rules="failedRules"
            />
            <b-alert
              variant="danger"
              :show="errors && errors[0] ? true : false"
              class="my-1"
            >
              <div class="alert-body">
                <p>
                  {{ errors[0] }}
                </p>
              </div>
            </b-alert>
          </validation-provider>
        </div>

        <hr>
      </div>

      <b-row>
        <b-col
          md="3"
          class="mb-1"
        >
          <custom-v-select
            v-model="subDiscrepancyType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            placeholder="Select SubDiscrepancy Type"
            label="label"
            :options="subDiscrepancyTypes.map(subDiscrepancyType => subDiscrepancyType.name)"
            :clearable="false"
          />
        </b-col>
        <b-col class="mb-1">
          <b-button
            variant="outline-secondary"
            :disabled="!subDiscrepancyType"
            @click="addDiscrepancy"
          >
            Add SubDiscrepancy
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import axios from 'axios'
import { isEqual, cloneDeep, debounce } from 'lodash'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ItemExchange from './BillModificationSubDiscrepancy/ItemExchange.vue'
import QuantityProblem from './BillModificationSubDiscrepancy/QuantityProblem.vue'
import RateProblem from './BillModificationSubDiscrepancy/RateProblem.vue'
import DiscountProblem from './BillModificationSubDiscrepancy/DiscountProblem.vue'
import PartyName from './BillModificationSubDiscrepancy/PartyName.vue'
import BatchMrpExpProblem from './BillModificationSubDiscrepancy/BatchMrpExpProblem.vue'
import ModeChange from './BillModificationSubDiscrepancy/ModeChange.vue'

import { billModifictionSubDiscrepancyTypes } from './discrepancy-types'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BAlert,
    ValidationProvider,
    CustomVSelect,
    ItemExchange,
    QuantityProblem,
    RateProblem,
    DiscountProblem,
    PartyName,
    BatchMrpExpProblem,
    ModeChange,
  },
  props: {
    itemOptions: {
      type: Array,
      required: true,
    },
    partyOptions: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    validationId: {
      type: String,
      required: true,
    },
    parentIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      record: {},
      billOptions: [],
      subDiscrepancyType: null,
      subDiscrepancyTypes: billModifictionSubDiscrepancyTypes,
    }
  },
  computed: {
    out() {
      return cloneDeep(this.record)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
    this.fetchBillOptions()
  },
  methods: {
    setInternalState() {
      this.record = cloneDeep(this.value)
    },

    async fetchBillOptions() {
      const res = await axios.get('/accounts/bill_options')
      this.billOptions = res.data.data
    },

    onBillSearch: debounce(function dummyName(search, loading) {
      this.refreshBillOptions(search, loading)
    }, 500),

    refreshBillOptions(search, loading) {
      loading(true)
      axios.get('/accounts/bill_options', {
        params: {
          search_term: search,
        },
      }).then(res => {
        this.billOptions = res.data.data
        loading(false)
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.detail || 'Error fetching bill options',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        loading(false)
      })
    },
    addDiscrepancy() {
      const subDiscrepancyTypeRecord = this.subDiscrepancyTypes.find(subDiscrepancyType => subDiscrepancyType.name === this.subDiscrepancyType)
      const sunDescrepancy = {
        type: this.subDiscrepancyType,
        details: cloneDeep(subDiscrepancyTypeRecord.defaultData),
      }

      this.record.items.push(sunDescrepancy)
    },
    deleteDiscrepancy(index) {
      this.record.items.splice(index, 1)
    },
  },
}
</script>

<style scoped>
.sub-discrepancy-wrapper{
  border: 1px solid rgb(207, 205, 205);
  border-radius: 5px;
  padding: 5px
}
</style>
