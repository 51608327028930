<template>
  <div>
    <b-row>
      <b-col md="3">
        <validation-provider
          #default="{errors}"
          rules="required"
          name="Month"
        >
          <b-form-group label="Month">
            <b-form-input
              v-model="record.month"
              :state="errors.length > 0 ? false:null"
              type="month"
              placeholder="Month"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="3">
        <validation-provider
          #default="{errors}"
          rules="required"
          name="Party"
          vid="podSupplyParty"
        >
          <b-form-group
            label="Party"
            :state="errors.length > 0 ? false:null"
          >
            <custom-v-select
              v-model="record.party"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="buyersOptions"
              label="text"

              placeholder="Select Party"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider
          #default="{errors}"
          rules="required"
          name="MR Details"
        >
          <b-form-group label="MR Details">
            <b-form-textarea
              v-model="record.mrDetails"
              :state="errors.length > 0 ? false:null"
              placeholder="MR Details"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <b-form-group label="Remarks">
          <b-form-textarea
            v-model="record.remarks"
            placeholder="Remarks"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    CustomVSelect,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      buyersOptions: [],
    }
  },
  computed: {
    record: {
      get() {
        return this.$props.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/accounts/buyers_options')
        .then(res => {
          this.buyersOptions = res.data.data
        })
    },
  },
}
</script>
