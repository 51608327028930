<template>
  <b-form-group label="Discrepancy Type">
    <custom-v-select
      v-model="selectedOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="options"
      multiple
      :close-on-select="false"
      :deselect-from-dropdown="true"
      placeholder="Select Discrepancy Type"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'

export default {
  components: { BFormGroup, CustomVSelect },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedOptions: {
      get() {
        return this.$props.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
}
</script>
