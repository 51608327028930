var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Invoice Item No.","vid":(_vm.validationId + "_invoice_item_no"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Invoice Item No."}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","min":"0","placeholder":"Invoice Item No."},model:{value:(_vm.record.invoiceItemNumber),callback:function ($$v) {_vm.$set(_vm.record, "invoiceItemNumber", $$v)},expression:"record.invoiceItemNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Item Name","vid":(_vm.validationId + "_item_name"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Item Name","state":errors.length > 0 ? false : null}},[_c('custom-v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.itemOptions,"label":"text","placeholder":"Select Item Name","state":errors.length > 0 ? false:null,"reduce":function (option) { return option.id; }},model:{value:(_vm.record.itemName),callback:function ($$v) {_vm.$set(_vm.record, "itemName", $$v)},expression:"record.itemName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Remarks","vid":(_vm.validationId + "_remarks"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Remarks"}},[_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"rows":"1","placeholder":"Remaks"},model:{value:(_vm.record.remarks),callback:function ($$v) {_vm.$set(_vm.record, "remarks", $$v)},expression:"record.remarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-table-simple',{staticClass:"discrepancy-table",attrs:{"bordered":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',[_vm._v("#")]),_c('b-th',[_vm._v("Quantity")]),_c('b-th',[_vm._v("Batch")]),_c('b-th',[_vm._v("MRP")]),_c('b-th',[_vm._v("Damaged Quantity")])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-th',[_vm._v(" Invoice ")]),_c('b-td',[_c('validation-provider',{attrs:{"name":"Invoice Quantity","vid":(_vm.validationId + "_total_quantiy"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","min":"0","placeholder":"Invoice Quantity"},model:{value:(_vm.record.totalQuantity),callback:function ($$v) {_vm.$set(_vm.record, "totalQuantity", $$v)},expression:"record.totalQuantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"name":"Invoice Batch","vid":(_vm.validationId + "_item_batch"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Invoice Batch"},model:{value:(_vm.record.itemBatch),callback:function ($$v) {_vm.$set(_vm.record, "itemBatch", $$v)},expression:"record.itemBatch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"name":"Invoice MRP","vid":(_vm.validationId + "_item_mrp"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","min":"0","step":"0.01","placeholder":"Invoice MRP"},model:{value:(_vm.record.itemMRP),callback:function ($$v) {_vm.$set(_vm.record, "itemMRP", $$v)},expression:"record.itemMRP"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-td',[_c('validation-provider',{attrs:{"name":"Damaged Quantity","vid":(_vm.validationId + "_damaged_quantiy"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","min":"0","placeholder":"Damaged Quantity"},model:{value:(_vm.record.damagedQuantity),callback:function ($$v) {_vm.$set(_vm.record, "damagedQuantity", $$v)},expression:"record.damagedQuantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }