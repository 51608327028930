<template>
  <div>
    <b-row>
      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="No. of Cartons"
          :vid="`${validationId}_cartons`"
          rules="required"
        >
          <b-form-group
            label="No. of Cartons*"
            :state="errors.length > 0 ? false : null"
          >
            <b-form-input
              v-model="record.noOfCartons"
              :state="errors.length > 0 ? false:null"
              placeholder="No. of Cartons"
              type="number"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Return Type"
          :vid="`${validationId}_return_type`"
          rules="required"
        >
          <b-form-group
            label="Return Type*"
            :state="errors.length > 0 ? false : null"
          >
            <custom-v-select
              v-model="record.returnType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              placeholder="Select Return Type"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Remarks"
          :vid="`${validationId}_remarks`"
          rules=""
        >
          <b-form-group
            label="Remarks"
          >
            <b-form-textarea
              v-model="record.remarks"
              :state="errors.length > 0 ? false:null"
              rows="1"
              placeholder="Remaks"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col md="3">
        <b-form-group
          label="Return Time:"
        >
          <p>{{ record.returnTime }}</p>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { isEqual, cloneDeep } from 'lodash'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import moment from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    CustomVSelect,
    BFormTextarea,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    validationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      record: {},
      typeOptions: [
        'Sales Return', 'Exp/Brk Return',
      ],
    }
  },
  computed: {
    out() {
      return cloneDeep(this.record)
    },
    returnTime() {
      const currentUTCTime = moment().utc().tz('Asia/Kolkata').format('DD-MM-YYYY LT')
      return currentUTCTime
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
    this.record.returnTime = this.returnTime
  },
  methods: {
    setInternalState() {
      this.record = cloneDeep(this.value)
    },
  },
}
</script>
