<template>
  <b-table-simple
    bordered
    hover
    class="compact-table"
  >
    <b-thead>
      <b-tr>
        <b-th>#</b-th>
        <b-th>Deparment</b-th>
        <b-th>Status</b-th>
        <b-th>Remarks</b-th>
        <b-th>Closed at</b-th>
        <b-th>Closed by</b-th>
        <b-th>Actions</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <discrepancy-remark-row
        v-for="(discrepancyRemark, discrepancyRemarkIndex) of discrepancyRemarks"
        :key="discrepancyRemarkIndex"
        :discrepancy-remark="discrepancyRemark"
        :index="discrepancyRemarkIndex"
        :is-buyer-ticket="isBuyerTicket"
        @onDiscrepancyClose="$emit('onDiscrepancyClose')"
      />
    </b-tbody>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh,
} from 'bootstrap-vue'

import DiscrepancyRemarkRow from './DiscrepancyRemarkRow.vue'

export default {
  components: {
    BTableSimple, BThead, BTbody, BTr, BTh, DiscrepancyRemarkRow,
  },
  props: {
    discrepancyRemarks: {
      type: Array,
      required: true,
    },
    isBuyerTicket: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
