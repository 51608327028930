<template>
  <div>
    <b-row>
      <b-col md="3">
        <validation-provider
          #default="{errors}"
          rules="required"
          name="Party"
          vid="semiOrExportParty"
        >
          <b-form-group
            label="Party"
            :state="errors.length > 0 ? false:null"
          >
            <custom-v-select
              v-model="record.party"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="buyersOptions"
              label="text"
              placeholder="Select Party"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider
          #default="{errors}"
          rules="required"
          name="Discount"
          vid="semiOrExportDiscount"
        >
          <b-form-group
            label="Discount"
            :state="errors.length > 0 ? false:null"
          >
            <custom-v-select
              v-model="record.discount"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="discountOptions"
              placeholder="Select Discount"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import CustomVSelect from '@/components/UI/CustomVSelect.vue'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    BFormGroup,
    CustomVSelect,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      buyersOptions: [],
      discountOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    }
  },
  computed: {
    record: {
      get() {
        return this.$props.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
  },
  created() {
    this.fetchOptions()
  },
  methods: {
    fetchOptions() {
      axios.get('/accounts/buyers_options')
        .then(res => {
          this.buyersOptions = res.data.data
        })
    },
  },
}
</script>
