<template>
  <b-modal
    v-model="modalShow"
    size="xl"
    scrollable
    title="Buyer Ticket Details"
    @hidden="handleCloseDialog"
  >

    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <div v-if="!loading && !loadingError">

      <b-table-simple
        responsive
        hover
        class="compact-table"
      >
        <b-tbody>
          <b-tr>
            <b-th>
              Ticket Id
            </b-th>
            <b-td>
              {{ ticketDetails.ticket_id }}
            </b-td>
            <b-th>
              Created At
            </b-th>
            <b-td>
              <span v-if="ticketDetails.created_at">
                {{ formatedDate(ticketDetails.created_at, 'DD-MM-YYYY hh:mm:ss A') }}
              </span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Buyer
            </b-th>
            <b-td>
              {{ ticketDetails.buyer__identifier }}
            </b-td>
            <b-th>
              Created By
            </b-th>
            <b-td colspan="3">
              {{ ticketDetails.created_by__fullname }}
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Status
            </b-th>
            <b-td>
              <status-icon :value="ticketDetails.status" />
            </b-td>
            <b-th>
              Closed At
            </b-th>
            <b-td>
              <span v-if="ticketDetails.closed_at">
                {{ formatedDate(ticketDetails.closed_at, 'DD-MM-YYYY hh:mm:ss A') }}
              </span>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Basket No
            </b-th>
            <b-td>
              <status-icon :value="ticketDetails.basket_no" />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <h6>Discrepancies Count: {{ ticketDetails.buyer_discrepancies.length }}</h6>

      <div
        v-for="(discrepancy, discrepancyIndex) of ticketDetails.buyer_discrepancies"
        :key="discrepancyIndex"
      >
        <h6
          class="fw-bold "
          style="text-decoration: underline;"
        >{{ discrepancyIndex + 1 }}.  {{ discrepancy.type }}</h6>
        <display-discrepancy
          :discrepancy="parseDiscrepancy(discrepancy)"
          is-buyer-ticket
          :parent-index="discrepancyIndex + 1"
          @onDiscrepancyClose="handleCloseDiscrepancy"
        />
      </div>

    </div>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="secondary"
          class="float-right"
          @click="handleCloseDialog"
        >
          Close
        </b-button>
      </div>
    </template>
  </b-modal>

</template>
<script>
import {
  BAlert, BSpinner, BTableSimple, BTbody, BTd, BTr, BTh, BButton,
  BModal,
} from 'bootstrap-vue'
import axios from 'axios'
import dateMixin from '@/mixins/dateMixin'
import StatusIcon from '@/components/UI/StatusIcon.vue'
import DisplayDiscrepancy from '@/components/Tickets/TicketDiscrepancy/DisplayDiscrepancy.vue'

export default {
  components: {
    BButton,
    BAlert,
    BSpinner,
    BTableSimple,
    BTbody,
    BTd,
    BTr,
    BTh,
    BModal,
    StatusIcon,
    DisplayDiscrepancy,
  },
  mixins: [dateMixin],
  props: {
    ticketId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      modalShow: true,
      loading: true,
      ticketDetails: {},
      loadingError: null,
    }
  },
  created() {
    this.fetchDetails()
  },
  methods: {
    async fetchDetails() {
      try {
        this.loading = true
        const buyerTicketRes = await axios.get(`/tickets/buyer-ticket/${this.ticketId}`)
        this.ticketDetails = buyerTicketRes.data
        this.loadingError = null
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Error fetching ticket details'
      } finally {
        this.loading = false
      }
    },
    parseDiscrepancy(data) {
      return {
        id: data.id,
        type: data.type,
        details: data.details,
        discrepancy_remarks: data.buyer_discrepancy_remarks,
      }
    },

    async handleCloseDiscrepancy() {
      this.$emit('onDiscrepancyClose')

      try {
        const { data } = await axios.get(`/tickets/buyer-ticket/${this.ticketId}`)
        this.ticketDetails = { ...data }
      } catch (error) {
        this.loadingError = error?.response?.data?.detail || 'Failed to set ticket data'
      }
    },
    handleCloseDialog() {
      this.$emit('onCloseDialog')
      this.$emit('modal-closed')
      this.modalShow = false
    },
  },
}
</script>
