<template>
  <div>
    <b-row>
      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="Old Discount"
          :vid="`${validationId}_old_discount`"
          rules="required"
        >
          <b-form-group
            label="Old Discount*"
            :state="errors.length > 0 ? false : null"
          >
            <b-form-input
              v-model="record.oldDiscount"
              :state="errors.length > 0 ? false:null"
              type="number"
              min="0"
              step="0.01"
              placeholder="Old Discount"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="2">
        <validation-provider
          #default="{ errors }"
          name="New Discount"
          :vid="`${validationId}_new_discount`"
          rules="required"
        >
          <b-form-group
            label="New Discount*"
            :state="errors.length > 0 ? false : null"
          >
            <b-form-input
              v-model="record.newDiscount"
              :state="errors.length > 0 ? false:null"
              type="number"
              min="0"
              placeholder="New Discount"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <validation-provider
          #default="{ errors }"
          name="Remarks"
          :vid="`${validationId}_remarks`"
          rules=""
        >
          <b-form-group
            label="Remarks"
          >
            <b-form-textarea
              v-model="record.remarks"
              :state="errors.length > 0 ? false:null"
              rows="1"
              placeholder="Remaks"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-col>

    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { isEqual, cloneDeep } from 'lodash'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    BFormTextarea,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    validationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      record: {},
    }
  },
  computed: {
    out() {
      return cloneDeep(this.record)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.record = cloneDeep(this.value)
    },
  },
}
</script>
