<template>
  <div
    class="discrepancy-table-wrapper"
  >
    <b-table-simple
      bordered
      class="discrepancy-table"
    >
      <col width="350px">
      <col width="300px">
      <col width="300px">
      <col width="50px">
      <b-thead>
        <b-tr>
          <b-th>
            Item Name*
          </b-th>
          <b-th>
            Quantity*
          </b-th>
          <b-th>
            Expiry*
          </b-th>
          <b-th />
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(item, itemIndex) of record.items"
          :key="itemIndex"
        >
          <b-td>
            <validation-provider
              #default="{ errors }"
              name="Item Name"
              :vid="`${validationId}_${itemIndex}_item_name`"
              rules="required"
            >
              <b-form-group>
                <custom-v-select
                  v-model="record.items[itemIndex].itemName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="itemOptions"
                  label="text"
                  placeholder="Select Item Name"
                  :state="errors.length > 0 ? false:null"
                  :reduce="option => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-td>

          <b-td>
            <validation-provider
              #default="{ errors }"
              name="Quantity"
              :vid="`${validationId}_${itemIndex}_quantity`"
              rules="required"
            >
              <b-form-group>
                <b-form-input
                  v-model="record.items[itemIndex].quantity"
                  :state="errors.length > 0 ? false:null"
                  type="number"
                  min="0"
                  placeholder="Quantity"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-td>

          <b-td>
            <validation-provider
              #default="{ errors }"
              name="Expiry"
              :vid="`${validationId}_${itemIndex}_expiry`"
              rules="required"
            >
              <b-form-group>
                <b-form-input
                  v-model="record.items[itemIndex].expiry"
                  :state="errors.length > 0 ? false:null"
                  type="month"
                  placeholder="Expiry"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-td>
          <b-td>
            <div
              v-if="record.items.length > 1"
              class="mx-1"
            >
              <feather-icon
                v-b-tooltip.hover="{boundary:'window'}"
                title="Delete"
                icon="Trash2Icon"
                size="18"
                class="cursor-pointer "
                @click="deleteItem(itemIndex)"
              />
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-button
      v-b-tooltip.hover="{boundary:'window'}"
      variant="primary"
      title="Add Item"
      class="my-1"
      @click="addItem"
    >
      <feather-icon
        icon="PlusIcon"
        size="18"
      /> Add
    </b-button>
  </div>

</template>

<script>

import {
  BButton, BFormGroup, BFormInput, VBTooltip,
  BTableSimple, BTbody, BThead, BTr, BTh, BTd,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { isEqual, cloneDeep } from 'lodash'

import CustomVSelect from '@/components/UI/CustomVSelect.vue'

import {
  expBrkReturnSubItemDefaultValues,
} from '@/components/Tickets/TicketDiscrepancy/discrepancy-types'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    CustomVSelect,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTh,
    BTd,
  },
  props: {
    itemOptions: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    validationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      record: {},
    }
  },
  computed: {
    out() {
      return cloneDeep(this.record)
    },
  },
  watch: {
    out: {
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit('input', val)
        }
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!isEqual(val, this.out)) {
          this.setInternalState()
        }
      },
      deep: true,
    },
  },
  created() {
    this.setInternalState()
  },
  methods: {
    setInternalState() {
      this.record = cloneDeep(this.value)
    },
    addItem() {
      const item = { ...expBrkReturnSubItemDefaultValues }
      this.record.items.push(item)
    },
    deleteItem(index) {
      this.record.items.splice(index, 1)
    },
  },
}
</script>
