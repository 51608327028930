var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Item Name","vid":(_vm.validationId + "_item_name"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Item Name*","state":errors.length > 0 ? false : null}},[_c('custom-v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.itemOptions,"label":"text","placeholder":"Select Item Name","state":errors.length > 0 ? false:null,"reduce":function (option) { return option.id; }},model:{value:(_vm.record.itemName),callback:function ($$v) {_vm.$set(_vm.record, "itemName", $$v)},expression:"record.itemName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Current Bonus","vid":(_vm.validationId + "_current_bonus"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Current Bonus*","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Current Bonus"},model:{value:(_vm.record.currentBonus),callback:function ($$v) {_vm.$set(_vm.record, "currentBonus", $$v)},expression:"record.currentBonus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Desired Bonus","vid":(_vm.validationId + "_desired_bonus"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Desired Bonus*","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Desired Bonus"},model:{value:(_vm.record.desiredBonus),callback:function ($$v) {_vm.$set(_vm.record, "desiredBonus", $$v)},expression:"record.desiredBonus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Bill No.","vid":(_vm.validationId + "_bill_no"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Bill No.","state":errors.length > 0 ? false : null}},[_c('custom-v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.billOptions,"label":"text","placeholder":"Select Bill","state":errors.length > 0 ? false:null,"reduce":function (option) { return option.text; }},on:{"search":_vm.onBillSearch},model:{value:(_vm.record.billNo),callback:function ($$v) {_vm.$set(_vm.record, "billNo", $$v)},expression:"record.billNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Quantity","vid":(_vm.validationId + "_quantity"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Quantity","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","min":"0","placeholder":"Quantity"},model:{value:(_vm.record.quantity),callback:function ($$v) {_vm.$set(_vm.record, "quantity", $$v)},expression:"record.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"Batch","vid":(_vm.validationId + "_batch"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Batch","state":errors.length > 0 ? false:null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Batch"},model:{value:(_vm.record.batch),callback:function ($$v) {_vm.$set(_vm.record, "batch", $$v)},expression:"record.batch"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"2"}},[_c('validation-provider',{attrs:{"name":"MRP","vid":(_vm.validationId + "_mrp"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"MRP","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"number","min":"0","step":"0.01","placeholder":"MRP"},model:{value:(_vm.record.mrp),callback:function ($$v) {_vm.$set(_vm.record, "mrp", $$v)},expression:"record.mrp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Remarks","vid":(_vm.validationId + "_remarks"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Remarks"}},[_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false:null,"rows":"1","placeholder":"Remaks"},model:{value:(_vm.record.remarks),callback:function ($$v) {_vm.$set(_vm.record, "remarks", $$v)},expression:"record.remarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }