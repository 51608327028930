<template>
  <div class="m-1">
    <div v-if="!isBuyerTicket && discrepancy.type !== 'POD Supply' && discrepancy.type !== 'Semi/Export'">
      <p>
        <span
          class="font-weight-bold"
        >
          Invoice Item No:</span> {{ discrepancy.details.invoiceItemNumber }}
      </p>
      <p>
        <span class="font-weight-bold">Item Name:</span> {{ discrepancy.details.itemName }}
      </p>
    </div>

    <div v-if="discrepancy.type === 'Batch Problem'">
      <b-table-simple
        bordered
        hover
        class="compact-table"
      >
        <b-thead>
          <b-tr>
            <b-th>#</b-th>
            <b-th>Invoice</b-th>
            <b-th
              v-for="(receivedItem, receivedItemIndex) in discrepancy.details.receivedItems"
              :key="receivedItemIndex"
            >Physical #{{ receivedItemIndex + 1 }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>Quantity</b-td>
            <b-td>{{ discrepancy.details.invoiceQuantity }}</b-td>

            <b-td
              v-for="(receivedItem, receivedItemIndex) in discrepancy.details.receivedItems"
              :key="receivedItemIndex"
              :class="{
                'discrepancy-matching-value': discrepancy.details.invoiceQuantity === receivedItem.physicalQuantity,
                'discrepancy-not-matching-value': discrepancy.details.invoiceQuantity !== receivedItem.physicalQuantity
              }"
            >{{ receivedItem.physicalQuantity }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>Batch</b-td>
            <b-td>{{ discrepancy.details.invoiceBatch }}</b-td>

            <b-td
              v-for="(receivedItem, receivedItemIndex) in discrepancy.details.receivedItems"
              :key="receivedItemIndex"
              :class="{
                'discrepancy-matching-value': discrepancy.details.invoiceBatch === receivedItem.physicalBatch,
                'discrepancy-not-matching-value': discrepancy.details.invoiceBatch !== receivedItem.physicalBatch
              }"
            >{{ receivedItem.physicalBatch }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>Expiry</b-td>
            <b-td>{{ discrepancy.details.invoiceExpiry }}</b-td>

            <b-td
              v-for="(receivedItem, receivedItemIndex) in discrepancy.details.receivedItems"
              :key="receivedItemIndex"
              :class="{
                'discrepancy-matching-value': discrepancy.details.invoiceExpiry === receivedItem.physicalExpiry,
                'discrepancy-not-matching-value': discrepancy.details.invoiceExpiry !== receivedItem.physicalExpiry
              }"
            >{{ receivedItem.physicalExpiry }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>MRP</b-td>
            <b-td>{{ formateNumber(discrepancy.details.invoiceMRP) }}</b-td>

            <b-td
              v-for="(receivedItem, receivedItemIndex) in discrepancy.details.receivedItems"
              :key="receivedItemIndex"
              :class="{
                'discrepancy-matching-value': discrepancy.details.invoiceMRP === receivedItem.physicalMRP,
                'discrepancy-not-matching-value': discrepancy.details.invoiceMRP !== receivedItem.physicalMRP
              }"
            >{{ formateNumber(receivedItem.physicalMRP) }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div v-else-if="discrepancy.type === 'Item Exchanged'">
      <b-table-simple
        bordered
        hover
        class="compact-table"
      >
        <b-thead>
          <b-tr>
            <b-th>#</b-th>
            <b-th>Invoice</b-th>
            <b-th
              v-for="(receivedItem, receivedItemIndex) in discrepancy.details.receivedItems"
              :key="receivedItemIndex"
            >Physical #{{ receivedItemIndex + 1 }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>Item Name</b-td>
            <td />
            <b-td
              v-for="(receivedItem, receivedItemIndex) in discrepancy.details.receivedItems"
              :key="receivedItemIndex"
            >{{ receivedItem.itemName }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>Quantity</b-td>
            <b-td>{{ discrepancy.details.invoiceQuantity }}</b-td>
            <b-td
              v-for="(receivedItem, receivedItemIndex) in discrepancy.details.receivedItems"
              :key="receivedItemIndex"
              :class="{
                'discrepancy-matching-value': discrepancy.details.invoiceQuantity === receivedItem.physicalQuantity,
                'discrepancy-not-matching-value': discrepancy.details.invoiceQuantity !== receivedItem.physicalQuantity
              }"
            >{{ receivedItem.physicalQuantity }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>Batch</b-td>
            <b-td>{{ discrepancy.details.invoiceBatch }}</b-td>
            <b-td
              v-for="(receivedItem, receivedItemIndex) in discrepancy.details.receivedItems"
              :key="receivedItemIndex"
              :class="{
                'discrepancy-matching-value': discrepancy.details.invoiceBatch === receivedItem.physicalBatch,
                'discrepancy-not-matching-value': discrepancy.details.invoiceBatch !== receivedItem.physicalBatch
              }"
            >{{ receivedItem.physicalBatch }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>Expiry</b-td>
            <b-td>{{ discrepancy.details.invoiceExpiry }}</b-td>
            <b-td
              v-for="(receivedItem, receivedItemIndex) in discrepancy.details.receivedItems"
              :key="receivedItemIndex"
              :class="{
                'discrepancy-matching-value': discrepancy.details.invoiceExpiry === receivedItem.physicalExpiry,
                'discrepancy-not-matching-value': discrepancy.details.invoiceExpiry !== receivedItem.physicalExpiry
              }"
            >{{ receivedItem.physicalExpiry }}</b-td>
          </b-tr>

          <b-tr>
            <b-td>MRP</b-td>
            <b-td>{{ formateNumber(discrepancy.details.invoiceMRP) }}</b-td>

            <b-td
              v-for="(receivedItem, receivedItemIndex) in discrepancy.details.receivedItems"
              :key="receivedItemIndex"
              :class="{
                'discrepancy-matching-value': discrepancy.details.invoiceMRP === receivedItem.physicalMRP,
                'discrepancy-not-matching-value': discrepancy.details.invoiceMRP !== receivedItem.physicalMRP
              }"
            >{{ formateNumber(receivedItem.physicalMRP) }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div v-else-if="discrepancy.type === 'Item Short'">
      <b-table-simple
        bordered
        hover
        class="compact-table"
      >
        <b-thead>
          <b-tr>
            <b-th>Invoice Batch</b-th>
            <b-th>
              Invoice MRP
            </b-th>
            <b-th>Invoice Quantity</b-th>
            <b-th>Received Quantity</b-th>
            <b-th>Short Quantity</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>{{ discrepancy.details.itemBatch }}</b-td>

            <b-td>
              {{ formateNumber(discrepancy.details.itemMRP) }}
            </b-td>

            <b-td>{{ discrepancy.details.invoiceQuantity }}</b-td>

            <b-td>{{ discrepancy.details.receivedQuantity }}</b-td>

            <b-td>{{ discrepancy.details.invoiceQuantity - discrepancy.details.receivedQuantity }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div v-else-if="discrepancy.type === 'Item Damage'">
      <p><span class="font-weight-bold">Remarks:</span> {{ discrepancy.details.remarks }}</p>
      <b-table-simple
        bordered
        hover
        class="compact-table"
      >
        <b-thead>
          <b-tr>
            <b-th>Invoice Batch</b-th>
            <b-th>
              Invoice MRP
            </b-th>
            <b-th>Invoice Quantity</b-th>
            <b-th>Damaged Quantity</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>{{ discrepancy.details.itemBatch }}</b-td>
            <b-td>
              {{ formateNumber(discrepancy.details.itemMRP) }}
            </b-td>
            <b-td>{{ discrepancy.details.totalQuantity }}</b-td>
            <b-td>{{ discrepancy.details.damagedQuantity }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div v-else-if="discrepancy.type === 'Item Extra'">
      <b-table-simple
        bordered
        hover
        class="compact-table"
      >
        <b-thead>
          <b-tr>
            <b-th>Invoice Batch</b-th>
            <b-th>
              Invoice MRP
            </b-th>
            <b-th>Invoice Quantity</b-th>
            <b-th>Received Quantity</b-th>
            <b-th>Extra Quantity</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>{{ discrepancy.details.itemBatch }}</b-td>
            <b-td>
              {{ formateNumber(discrepancy.details.itemMRP) }}
            </b-td>
            <b-td>{{ discrepancy.details.invoiceQuantity }}</b-td>
            <b-td>{{ discrepancy.details.receivedQuantity }}</b-td>
            <b-td>{{ discrepancy.details.receivedQuantity - discrepancy.details.invoiceQuantity }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div v-else-if="discrepancy.type === 'Bonus Problem'">
      <p><span class="font-weight-bold">Remarks:</span> {{ discrepancy.details.remarks }}</p>
      <b-table-simple
        bordered
        hover
        class="compact-table"
      >
        <b-thead>
          <b-tr>
            <b-th>Invoice Batch</b-th>
            <b-th>
              Invoice MRP
            </b-th>
            <b-th>Invoice Quantity</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>{{ discrepancy.details.itemBatch }}</b-td>
            <b-td>
              {{ formateNumber(discrepancy.details.itemMRP) }}
            </b-td>
            <b-td>{{ discrepancy.details.totalQuantity }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div v-else-if="discrepancy.type === 'Other Problem'">
      <p><span class="font-weight-bold">Remarks:</span> {{ discrepancy.details.remarks }}</p>
    </div>
    <div v-else-if="discrepancy.type === 'POD Supply'">
      <b-table-simple
        class="compact-table"
        fixed
        bordered
      >
        <b-thead>
          <b-tr>
            <b-th> Party </b-th>
            <b-th> Month </b-th>
            <b-th> MR Details </b-th>
            <b-th> Remarks </b-th>
            <b-th> File </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td> {{ discrepancy.details.party.text }} </b-td>
            <b-td> {{ discrepancy.details.month }} </b-td>
            <b-td> {{ discrepancy.details.mrDetails }} </b-td>
            <b-td>
              <p class="text-wrap mb-0">
                {{ discrepancy.details.remarks }}
              </p>
            </b-td>
            <b-td>
              <a
                v-if="discrepancy.file"
                :href="discrepancy.file"
                target="_blank"
              >
                <span>View File</span>
              </a>
              <b-row
                v-else
                no-gutters
              >
                <b-col>
                  <b-form-file
                    v-model="podDiscrepancyFile"
                  />
                </b-col>
                <b-col
                  cols="auto"
                  class="ml-1"
                >
                  <b-button
                    variant="primary"
                    :disabled="!podDiscrepancyFile || loading"
                    @click="handlePODFileUpload(discrepancy.id)"
                  >
                    Update
                    <b-spinner
                      v-if="loading"
                      small
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div v-else-if="discrepancy.type === 'Semi/Export'">
      <b-table-simple
        class="compact-table"
        bordered
        hover
        fixed
      >
        <b-thead>
          <b-tr>
            <b-th> Party </b-th>
            <b-th> Discount </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td> {{ discrepancy.details.party.text }} </b-td>
            <b-td> {{ discrepancy.details.discount }} </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>

    <div v-else-if="discrepancy.type === 'Bonus Correction'">
      <b-table-simple
        class="compact-table"
        responsive
        bordered
        hover
      >
        <b-thead>
          <b-tr>
            <b-th> Item Name </b-th>
            <b-th> Current Bonus </b-th>
            <b-th> Desired Bonus </b-th>
            <b-th> Bill No. </b-th>
            <b-th> Quantity</b-th>
            <b-th> Batch</b-th>
            <b-th> MRP</b-th>
            <b-th> Remarks</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td> {{ discrepancy.details.itemName }} </b-td>
            <b-td> {{ discrepancy.details.currentBonus }} </b-td>
            <b-td> {{ discrepancy.details.desiredBonus }} </b-td>
            <b-td> {{ discrepancy.details.billNo }} </b-td>
            <b-td> {{ discrepancy.details.quantity }} </b-td>
            <b-td> {{ discrepancy.details.batch }} </b-td>
            <b-td> {{ discrepancy.details.mrp }} </b-td>
            <b-td> {{ discrepancy.details.remarks }} </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div v-else-if="discrepancy.type === 'Bill Modification'">
      <p>
        <span class="font-weight-bold">Bill No: </span> {{ discrepancy.details.billNo }}
      </p>
      <div
        v-for="(item, itemIndex) in discrepancy.details.items"
        :key="itemIndex"
      >
        <h6
          style="text-decoration:underline;"
          class="mb-1"
        >
          {{ parentIndex }}.{{ itemIndex + 1 }}: {{ item.type }}
        </h6>
        <div class="m-1">
          <div v-if="item.type === 'Item Exchange'">
            <b-table-simple
              class="compact-table"
              responsive
              bordered
              hover
            >
              <b-thead>
                <b-tr>
                  <b-th>Old Item Name</b-th>
                  <b-th>Old Quantity</b-th>
                  <b-th>New Item Name</b-th>
                  <b-th>New Quantity</b-th>
                  <b-th>Change Type</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td> {{ item.details.oldItemName }} </b-td>
                  <b-td> {{ item.details.oldItemQty }} </b-td>
                  <b-td> {{ item.details.newItemName }} </b-td>
                  <b-td> {{ item.details.newItemQty }} </b-td>
                  <b-td> {{ item.details.remarks }} </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div v-else-if="item.type === 'Quantity Problem'">
            <b-table-simple
              class="compact-table"
              responsive
              bordered
              hover
            >
              <b-thead>
                <b-tr>
                  <b-th> Item Name</b-th>
                  <b-th> Old Quantity </b-th>
                  <b-th> New Quantity </b-th>
                  <b-th> Remarks</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td> {{ item.details.itemName }} </b-td>
                  <b-td> {{ item.details.oldQty }} </b-td>
                  <b-td> {{ item.details.newQty }} </b-td>
                  <b-td> {{ item.details.remarks }} </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div v-else-if="item.type === 'Rate Problem'">
            <b-table-simple
              class="compact-table"
              responsive
              bordered
              hover
            >
              <b-thead>
                <b-tr>
                  <b-th> Item Name</b-th>
                  <b-th> Old Rate </b-th>
                  <b-th> Want Rate </b-th>
                  <b-th> Remarks</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td> {{ item.details.itemName }} </b-td>
                  <b-td> {{ item.details.oldRate }} </b-td>
                  <b-td> {{ item.details.wantRate }} </b-td>
                  <b-td> {{ item.details.remarks }} </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div
            v-else-if="item.type === 'Discount Problem'"
          >
            <b-table-simple
              class="compact-table"
              responsive
              bordered
              hover
            >
              <b-thead>
                <b-tr>
                  <b-th> Old Discount </b-th>
                  <b-th> New Discount </b-th>
                  <b-th> Remarks</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td> {{ item.details.oldDiscount }} </b-td>
                  <b-td> {{ item.details.newDiscount }} </b-td>
                  <b-td> {{ item.details.remarks }} </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div v-else-if="item.type === 'Party Name'">
            <b-table-simple
              class="compact-table"
              responsive
              bordered
              hover
            >
              <b-thead>
                <b-tr>
                  <b-th> Old Party Name </b-th>
                  <b-th> New Party Name </b-th>
                  <b-th> Remarks</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td> {{ item.details.oldPartyName }} </b-td>
                  <b-td> {{ item.details.newPartyName }} </b-td>
                  <b-td> {{ item.details.remarks }} </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div v-else-if="item.type === 'Batch/MRP/Exp Problem'">
            <b-table-simple
              class="compact-table"
              responsive
              bordered
              hover
            >
              <b-thead>
                <b-tr>
                  <b-th> Item Name </b-th>
                  <b-th> Old Batch </b-th>
                  <b-th> New Batch </b-th>
                  <b-th> Old MRP </b-th>
                  <b-th> New MRP </b-th>
                  <b-th> Old Expiry </b-th>
                  <b-th> New Expiry </b-th>
                  <b-th> Remarks</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td> {{ item.details.itemName }} </b-td>
                  <b-td> {{ item.details.oldBatch }} </b-td>
                  <b-td> {{ item.details.newBatch }} </b-td>
                  <b-td> {{ item.details.oldMrp }} </b-td>
                  <b-td> {{ item.details.newMrp }} </b-td>
                  <b-td> {{ item.details.oldExpiry }} </b-td>
                  <b-td> {{ item.details.newExpiry }} </b-td>
                  <b-td> {{ item.details.remarks }} </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <div v-else-if="item.type === 'Mode Change'">
            <b-table-simple
              class="compact-table"
              responsive
              bordered
              hover
            >
              <b-thead>
                <b-tr>
                  <b-th>Change Type</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td> {{ item.details.changeType }} </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </div>

    </div>
    <div v-else-if="discrepancy.type === 'Sales Return'">
      <b-table-simple
        class="compact-table"
        responsive
        bordered
        hover
      >
        <b-thead>
          <b-tr>
            <b-th> # </b-th>
            <b-th> Item Name </b-th>
            <b-th> Quantity</b-th>
            <b-th> Reason</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item, itemIndex) in discrepancy.details.items"
            :key="itemIndex"
          >
            <b-th> {{ itemIndex + 1 }} </b-th>
            <b-td> {{ item.itemName }} </b-td>
            <b-td> {{ item.quantity }} </b-td>
            <b-td> {{ item.reason }} </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div v-else-if="discrepancy.type === 'Exp/Brk Return'">
      <b-table-simple
        class="compact-table"
        responsive
        bordered
        hover
      >
        <b-thead>
          <b-tr>
            <b-th> # </b-th>
            <b-th> Item Name </b-th>
            <b-th> Quantity</b-th>
            <b-th> Expiry</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item, itemIndex) in discrepancy.details.items"
            :key="itemIndex"
          >
            <b-th> {{ itemIndex + 1 }} </b-th>
            <b-td> {{ item.itemName }} </b-td>
            <b-td> {{ item.quantity }} </b-td>
            <b-td> {{ item.expiry }} </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <div v-else-if="discrepancy.type === 'Bulk Return'">
      <b-table-simple
        class="compact-table"
        responsive
        bordered
        hover
      >
        <b-thead>
          <b-tr>
            <b-th> No. Of Cartons </b-th>
            <b-th> Return Type </b-th>
            <b-th> Remarks </b-th>
            <b-th> Return Time </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td> {{ discrepancy.details.noOfCartons }} </b-td>
            <b-td> {{ discrepancy.details.returnType }} </b-td>
            <b-td> {{ discrepancy.details.remarks }} </b-td>
            <b-td> {{ discrepancy.details.returnTime }} </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>

    <p><span class="font-weight-bold">Discrepancy Remarks:</span></p>
    <discrepancy-remarks
      :discrepancy-remarks="discrepancy.discrepancy_remarks"
      :is-buyer-ticket="isBuyerTicket"
      @onDiscrepancyClose="$emit('onDiscrepancyClose')"
    />
  </div>
</template>

<script>

import {
  BTableSimple, BTbody, BThead, BTr, BTh, BTd, BFormFile, BButton, BRow, BCol, BSpinner,
} from 'bootstrap-vue'

import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formatMixin from '@/mixins/formatMixin'
import DiscrepancyRemarks from './DiscrepancyRemarks/DiscrepancyRemarks.vue'

export default {
  components: {
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTh,
    BTd,
    DiscrepancyRemarks,
    BFormFile,
    BButton,
    BRow,
    BCol,
    BSpinner,
  },
  mixins: [formatMixin],
  props: {
    discrepancy: {
      type: Object,
      required: true,
    },
    parentIndex: {
      type: Number,
      required: false,
      default: () => 0,
    },
    isBuyerTicket: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      podDiscrepancyFile: null,
      loading: false,
      errorMessage: null,
    }
  },
  methods: {
    async handlePODFileUpload(id) {
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('file', this.podDiscrepancyFile)
        const response = await axios.patch(`/tickets/supplier_ticket_discrepancy_file/${id}/`, formData)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response?.data?.message || 'Discrepancy File saved successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$emit('onDiscrepancyUpdate')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.message || 'Something went wrong!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
