<template>
  <div>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>

    <b-alert
      variant="danger"
      :show="loadingError ? true : false"
    >
      <div class="alert-body">
        <p>
          {{ loadingError }}
        </p>
      </div>
    </b-alert>

    <b-row class="mt-2">
      <b-col
        align="right"
      >
        <b-button
          variant="outline-primary"
          class="mb-1"
          @click="addTicketHandler"
        >
          Add Buyer Ticket
        </b-button>
      </b-col>
    </b-row>

    <b-table
      v-if="!loading && !loadingError"
      :items="results"
      :fields="tableFields"
      responsive
      striped
      hover
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="compact-table"
      no-local-sorting
      show-empty
      empty-text="No records found"
      @sort-changed="sortingChanged"
    >
      <template #head(select)>
        <b-form-checkbox
          v-model="allRecordsSeleted"
          :disabled="results.length === 0"
          @change="toggleRecordsSelection"
        />
      </template>

      <template #cell(select)="data">
        <b-form-checkbox
          v-model="selectedRecords"
          :value="data.item.id"
        />
      </template>

      <template #cell(status)="data">
        <status-icon :value="data.item.status" />
      </template>

      <template #cell(created_at)="data">
        <span v-if="data.item.created_at">
          {{ formatedDate(data.item.created_at, 'DD-MM-YYYY hh:mm:ss A') }}
        </span>
      </template>

      <template #cell(closed_at)="data">
        <span v-if="data.item.closed_at">
          {{ formatedDate(data.item.closed_at, 'DD-MM-YYYY hh:mm:ss A') }}
        </span>
      </template>

      <template #cell(actions)="data">
        <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="View Details"
          icon="EyeIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="displayDetails(data.item.id)"
        />

        <feather-icon
          v-if="!downloadingTicketIds.includes(data.item.id)"
          v-b-tooltip.hover="{boundary:'window'}"
          title="Download"
          icon="DownloadIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="downloadTicket(data.item.id, data.item.ticket_id)"
        />
        <b-spinner
          v-if="downloadingTicketIds.includes(data.item.id)"
          small
          label="Small Spinner"
        />

        <!-- <feather-icon
          v-b-tooltip.hover="{boundary:'window'}"
          title="Send Email"
          icon="SendIcon"
          size="20"
          class="cursor-pointer mr-1"
          @click="sendTicket = data.item"
        /> -->

      </template>
    </b-table>

    <detailed-pagination
      v-if="!loading && !loadingError"
      :total-items="totalItems"
      :per-page="perPage"
      :current-page="currentPage"
      @page-changed="(page) => {currentPage = page}"
    />

    <!-- <send-ticket
      v-if="sendTicket"
      :ticket="sendTicket"
      @modal-closed="sendTicket = null"
    /> -->

    <buyer-ticket-details
      v-if="openTicketId"
      :ticket-id="openTicketId"
      @modal-closed="openTicketId=null"
      @onDiscrepancyClose="handleDiscrepancyClose"
      @onCloseDialog="handleCloseDialog"
    />

    <buyer-ticket-form
      v-if="displayAddTicketForm"
      @modal-closed="displayAddTicketForm = false"
      @saved="fetchItems"
    />
  </div>
</template>

<script>
import {
  BSpinner, BTable, BAlert, VBTooltip, BCol, BRow, BButton, BFormCheckbox,
} from 'bootstrap-vue'
// import bus from '@/bus'
import StatusIcon from '@/components/UI/StatusIcon.vue'
import axios from 'axios'
import dateMixin from '@/mixins/dateMixin'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
// import SendTicket from '@/components/Tickets/SendTicket.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import download from 'downloadjs'
import BuyerTicketDetails from '@/components/Tickets/BuyerTickets/BuyerTicketDetails.vue'
import BuyerTicketForm from '@/components/Tickets/BuyerTickets/BuyerTicketForm.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },

  components: {
    BSpinner,
    BTable,
    BAlert,
    StatusIcon,
    BFormCheckbox,
    BCol,
    BRow,
    BButton,
    DetailedPagination,
    // SendTicket,
    BuyerTicketDetails,
    BuyerTicketForm,
  },
  mixins: [dateMixin],
  props: {
    filters: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
      loadingError: null,
      displayAddTicketForm: null,
      results: [],
      selectedRecords: [],
      tableFields: [
        { key: 'select' },
        { key: 'ticket_id', label: 'TICKET ID', sortable: true },
        { key: 'buyer__identifier', label: 'Buyer', sortable: true },
        { key: 'discrepancy_count', label: 'Discrepancy Count', sortable: true },
        { key: 'basket_no', label: 'Basket No', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'created_by__fullname', label: 'Created by', sortable: true },
        { key: 'created_at', label: 'CREATED AT', sortable: true },
        { key: 'closed_at', label: 'CLOSED AT', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      sortBy: 'ticket_id',
      sortDesc: true,
      currentPage: 1,
      perPage: 50,
      totalItems: 0,
      // sendTicket: null,
      downloadingTicketIds: [],
      allRecordsSeleted: false,
      openTicketId: null,
      isCloseDiscrepancyAvailable: false,
    }
  },
  watch: {
    currentPage() {
      this.fetchItems()
    },
    filters() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      } else {
        this.fetchItems()
      }
    },
  },
  created() {
    this.fetchItems()
  },
  methods: {
    toggleRecordsSelection(checked) {
      this.selectedRecords = checked ? this.results.map(result => result.id) : []
    },
    addTicketHandler() {
      this.displayAddTicketForm = true
    },
    sortingChanged(ctx) {
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchItems()
    },
    async fetchItems() {
      this.loading = true

      try {
        const response = await axios.post('/tickets/filtered_buyer_tickets', {
          page: this.currentPage,
          per_page: this.perPage,
          sort_by: this.sortBy,
          sort_desc: this.sortDesc,
          filters: { ...this.filters },
        })
        this.results = response.data.results
        this.totalItems = response.data.count
        this.loadingError = null
        this.loading = false
      } catch (error) {
        this.loading = false
        const errorResponse = error?.response
        if (errorResponse && errorResponse.status === 404 && this.currentPage > 1) {
          this.currentPage -= 1
          this.fetchItems()
        } else {
          this.loadingError = error?.response?.data?.detail || 'Error fetching tickets list'
        }
      }
    },
    async downloadTicket(recordId, ticketId) {
      this.downloadingTicketIds.push(recordId)

      try {
        const response = await axios.get(`/tickets/download_buyer_ticket/${recordId}`, {
          responseType: 'blob',
        })

        const contentType = response.headers['content-type']
        download(response.data, ticketId, contentType)
        this.downloading = false

        this.downloadingTicketIds = this.downloadingTicketIds.filter(id => id !== recordId)
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.error || 'Error downloading Buyer ticket',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.downloadingTicketIds = this.downloadingTicketIds.filter(id => id !== recordId)
      }
    },
    displayDetails(ticketId) {
      this.openTicketId = ticketId
    },
    handleDiscrepancyClose() {
      if (!this.isCloseDiscrepancyAvailable) {
        this.isCloseDiscrepancyAvailable = true
      }
    },

    async handleCloseDialog() {
      if (this.isCloseDiscrepancyAvailable) {
        await this.fetchItems()
        this.isCloseDiscrepancyAvailable = false
      }
    },
  },
}
</script>
