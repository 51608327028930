// ********************************************************
// **
// ** SUPPLIER DISCREPANCIES
// **
// ********************************************************

const batchProblemReceivedItemDefaultValues = {
  physicalBatch: null,
  physicalExpiry: null,
  physicalMRP: null,
  physicalQuantity: null,
}

const itemExchangedReceivedItemDefaultValues = {
  itemName: null,
  physicalBatch: null,
  physicalExpiry: null,
  physicalMRP: null,
  physicalQuantity: null,
}

const batchProblem = {
  name: 'Batch Problem',
  defaultData: {
    invoiceItemNumber: null,
    itemName: null,
    invoiceBatch: null,
    invoiceExpiry: null,
    invoiceMRP: null,
    invoiceQuantity: null,
    receivedItems: [
      {
        ...batchProblemReceivedItemDefaultValues,
      },
    ],
  },
}

const itemExchange = {
  name: 'Item Exchanged',
  defaultData: {
    invoiceItemNumber: null,
    itemName: null,
    invoiceBatch: null,
    invoiceExpiry: null,
    invoiceMRP: null,
    invoiceQuantity: null,
    receivedItems: [{
      ...itemExchangedReceivedItemDefaultValues,
    }],
  },
}

const itemShortage = {
  name: 'Item Short',
  defaultData: {
    invoiceItemNumber: null,
    itemName: null,
    itemBatch: null,
    itemMRP: null,
    invoiceQuantity: null,
    receivedQuantity: null,
  },
}

const itemDamage = {
  name: 'Item Damage',
  defaultData: {
    invoiceItemNumber: null,
    itemName: null,
    itemMRP: null,
    totalQuantity: null,
    itemBatch: null,
    damagedQuantity: null,
    remarks: null,
  },
}
const extraItem = {
  name: 'Item Extra',
  defaultData: {
    invoiceItemNumber: null,
    itemName: null,
    itemBatch: null,
    itemMRP: null,
    invoiceQuantity: null,
    receivedQuantity: null,
  },
}

const bonusProblem = {
  name: 'Bonus Problem',
  defaultData: {
    invoiceItemNumber: null,
    itemName: null,
    itemMRP: null,
    totalQuantity: null,
    itemBatch: null,
    remarks: null,
  },
}

const otherProblem = {
  name: 'Other Problem',
  defaultData: {
    invoiceItemNumber: null,
    itemName: null,
    remarks: null,
  },
}

const podSupply = {
  name: 'POD Supply',
  defaultData: {
    month: null,
    party: null,
    mrDetails: null,
    remarks: null, // optional
  },
}

const semiOrExport = {
  name: 'Semi/Export',
  defaultData: {
    party: null,
    discount: null,
  },
}

const discrepancyTypes = [
  batchProblem,
  itemExchange,
  itemShortage,
  itemDamage,
  extraItem,
  bonusProblem,
  podSupply,
  semiOrExport,
  otherProblem,
]

const discrepancies = [
  'Batch Problem',
  'Item Exchanged',
  'Item Short',
  'Item Damage',
  'Item Extra',
  'Bonus Problem',
  'POD Supply',
  'Semi/Export',
  'Other Problem',
]

// ********************************************************
// **
// ** BUYER DISCREPANCIES
// **
// ********************************************************

const bonusCorrection = {
  name: 'Bonus Correction',
  defaultData: {
    itemName: null, // required
    currentBonus: null, // required
    desiredBonus: null, // required
    billNo: null,
    quantity: null,
    batch: null,
    mrp: null,
    remarks: null,
  },
}

const billModifictionSubDiscrepancyTypes = [
  {
    name: 'Item Exchange',
    defaultData: {
      oldItemName: null, // required
      oldItemQty: null, // required
      newItemName: null, // required
      newItemQty: null, // required
      remarks: null,
    },
  },
  {
    name: 'Quantity Problem',
    defaultData: {
      itemName: null, // required
      oldQty: null, // required
      newQty: null, // required
      remarks: null,
    },
  },
  {
    name: 'Rate Problem',
    defaultData: {
      itemName: null, // required
      oldRate: null, // required
      wantRate: null, // required
      remarks: null,
    },
  },
  {
    name: 'Discount Problem',
    defaultData: {
      oldDiscount: null, // required
      newDiscount: null, // required
      remarks: null,
    },
  },
  {
    name: 'Party Name',
    defaultData: {
      oldPartyName: null, // required
      newPartyName: null, // required
      remarks: null, // required
    },
  },
  {
    name: 'Batch/MRP/Exp Problem',
    defaultData: {
      itemName: null, // required
      oldBatch: null,
      oldMrp: null,
      oldExpiry: null,
      newBatch: null,
      newMrp: null,
      newExpiry: null,
      remarks: null,
    },
  },
  {
    name: 'Mode Change',
    defaultData: {
      changeType: null, // required
    },
  },
]

const billModification = {
  name: 'Bill Modification',
  defaultData: {
    billNo: null, // required
    items: [],
  },
}

const salesReturnSubItemDefaultValues = {
  itemName: null, // required
  quantity: null, // required
  reason: null, // required
}

const salesReturn = {
  name: 'Sales Return',
  defaultData: {
    items: [
      { ...salesReturnSubItemDefaultValues },
    ],
  },
}

const expBrkReturnSubItemDefaultValues = {
  itemName: null, // required
  quantity: null, // required
  expiry: null, // required
}

const expBrkReturn = {
  name: 'Exp/Brk Return',
  defaultData: {
    items: [
      { ...expBrkReturnSubItemDefaultValues },
    ],
  },
}

const bulkReturn = {
  name: 'Bulk Return',
  defaultData: {
    noOfCartons: null,
    returnType: null,
    remarks: null,
    returnTime: null,
  },
}

const buyerDiscrepancyTypes = [
  bonusCorrection,
  billModification,
  salesReturn,
  expBrkReturn,
  bulkReturn,
]

const buyerTicketDiscrepancies = [
  'Bonus Correction',
  'Bill Modification',
  'Sales Return',
  'Exp/Brk Return',
  'Bulk Return',
]

module.exports = {
  discrepancies,
  discrepancyTypes,
  batchProblemReceivedItemDefaultValues,
  itemExchangedReceivedItemDefaultValues,

  buyerTicketDiscrepancies,
  buyerDiscrepancyTypes,
  salesReturnSubItemDefaultValues,
  expBrkReturnSubItemDefaultValues,
  billModifictionSubDiscrepancyTypes,
}
